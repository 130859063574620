
export default class DeviceGroupModel {

  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;

    this.group_type = obj.group_type;
    this.downlink_lifetime = obj.downlink_lifetime;
    this.trx_timeout = obj.trx_timeout;
    this.uplink_lifetime = obj.uplink_lifetime;
    this.blockchain_uid = obj.blockchain_uid;
    this.status = obj.status;
    this.created_at = obj.created_at;
    this.updated_at = obj.updated_at;
    this.user = obj.user;
    this.services = obj.services;
    this.tarif_str = obj.tarif_str;

    // TODO: Hard code - need to delete
    if (this.alt_services) {
      delete this.alt_services;
      this.alt_services = [];
    }
  }

  get dto() {

    // TODO: hardcode - need to find better way
    let outputServices = [];

    if (this.services && this.services.length) {
      outputServices = this.services.map((service) => {
        let copy = JSON.parse(JSON.stringify(service));
        delete copy.disableEditing;
        delete copy.editing;
        return copy;
      });
    }

    return {
      name: String(this.name),
      group_type: Number(this.group_type),
      downlink_lifetime: isNaN(this.downlink_lifetime) ? 0 : Number(this.downlink_lifetime),
      trx_timeout: isNaN(this.trx_timeout) ? 0 : Number(this.trx_timeout),
      uplink_lifetime: isNaN(this.uplink_lifetime) ? 0 : Number(this.uplink_lifetime),
      status: Number(this.status),
      services: outputServices,
      // tarif_str: String(this.tarif_str)
    };
  }
}
