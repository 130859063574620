
import Request from '@/helpers/request';
import { defaultPeriods, periodToTimestamp, rangeToTimestamp } from '@/helpers/timePeriods';

export default class TransactionsApi {
  static get allowedPeriods() {
    return defaultPeriods();
  }

  static periodToTimestamp(period) {
    return periodToTimestamp(period);
  }

  static rangeToTimestamp(range) {
    return rangeToTimestamp(range);
  }

  static prepareParams(params) {
    const period = TransactionsApi.rangeToTimestamp(params.range);

    return {
      device__device_group_uid: params.device__device_group_uid,
      ...period,
      ...{
        __order: (params.orderBy) ? `${params.orderBy.value} ${params.orderBy.desc ? 'desc' : 'asc'}` : null,
        __count: (params.limit) ? `${params.limit.count}` : null,
        __offset: (params.limit) ? `${params.limit.offset}` : null
      },
      term: params.term,
      ...params.sendMail &&
      {
        sendMail: true,
      },
      ...params.email &&
      {
        email: params.email,
      },
      ...params.type &&
      {
        type: params.type,
      },
      ...params.format &&
      {
        format: params.format,
      },
    };
  }

  static async getList(params) {
    delete params.format
    return Request.client.get('/transaction', {
      params: {
        ...TransactionsApi.prepareParams(params)
      }
    });
  }

  static async exportTrxToMail(params) {
    return Request.client.get('/transaction/export_trx_mail', {
      params: {
        ...TransactionsApi.prepareParams(params)
      }
    });
  }

  static async exportTrx(params) {
    return Request.client.get('/transaction/export_trx', {
      params: {
        ...TransactionsApi.prepareParams(params)
      }
    });
  }

  static async getChart(params) {
    return Request.client.get('/transaction/chart', {
      params: {
        ...TransactionsApi.prepareParams(params)
      }
    });
  }
}
