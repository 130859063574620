<template>
  <div class="uploader-placeholder">
    <h1 class="title">
      Drag and Drop files here
    </h1>
    <span class="or">or</span>
    <button-input @click.native="addFiles">
      <plus-icon
        slot="icon"
        fill="currentColor"
      />
      <span>Add files</span>
    </button-input>
    <span
      v-if="supported.length"
      class="supported"
    >Supported files: {{ supported.join(", ").toUpperCase() }}</span>
  </div>
</template>

<script>
import PlusIcon from '@/components/Icons/Plus';

export default {
  components: {
    PlusIcon
  },

  props: {
    supported: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    addFiles() {
      this.$emit('addFiles');
    }
  }
};
</script>

<style lang="scss" scoped>
.uploader-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;

  .title {
    @include h1;
    color: $notBlack;
  }

  .or {
    @include h2;
    font-weight: 300;
    margin: 16px 0;
    color: $grey70;
  }

  .supported {
    @include text-body;
    line-height: 24px;
    margin-top: 24px;
    color: $grey70;
  }
}
</style>