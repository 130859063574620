import DashboardApi from '@/api/dashboard';

export default {
  namespaced: true,

  state: {
    data: {},
    meta: {},
    loading: false,
    error: null
  },

  actions: {
    async fetch(ctx) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const { data, meta } = await DashboardApi.fetchDeviceGroups();
        ctx.commit('setData', data);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'dashboard_device_groups', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setData(state, data) {
      state.data = data;
    }
  },

  getters: {
    loading: state => state.loading,
    error: state => state.error,
    meta: state => state.meta,
    data: state => state.data
  }
};
