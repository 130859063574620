<template>
  <div class="uploader">
    <form ref="form">
      <input
        ref="fileinput"
        type="file"
        multiple
        hidden
        @change="addFiles"
      >
    </form>
    <div
      @drop.prevent="addFiles"
      @dragover.prevent
    >
      <uploader-placeholder
        v-if="!files.length"
        :supported="formats"
        @addFiles="addManually"
      />
      <uploader-content
        v-else
        :files="files"
        @addFiles="addManually"
        @deleteFile="deleteFile"
        @retry="retry"
      />
    </div>
  </div>
</template>

<script>
import UploaderPlaceholder from './UploaderPlaceholder';
import UploaderContent from './UploaderContent';

import Axios from 'axios';

const API_URL = 'http://5dc1aa9d6ca10a0014d5db3a.mockapi.io/images';

export default {
  components: {
    UploaderPlaceholder,
    UploaderContent
  },

  props: {
    url: {
      type: String,
      default: 'http://127.0.0.1:1337/uploadHere'
    },

    formats: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      files: []
    };
  },

  created() {
    this.preloadFiles();
  },

  methods: {
    async addFiles(event) {
      const files = this.getFilesFromEvent(event);

      for (const file of files) {
        const data = URL.createObjectURL(file);
        const newFile = {
          source: file,
          data,
          success: false,
          error: false,
          progress: null
        };
        this.files.push(newFile);

        await this.uploadFile(newFile);
      }

      this.$refs.form.reset();
    },

    addManually() {
      this.$refs.fileinput.click();
    },

    getFilesFromEvent(event) {
      if (event && event.dataTransfer && event.dataTransfer.files) {
        return event.dataTransfer.files;
      } else if (event && event.target && event.target.files) {
        return event.target.files;
      } else {
        return [];
      }
    },

    async uploadFile(file) {
      const formData = new FormData();
      formData.append('file', file.source);

      file.error = false;
      file.success = false;

      try {
        await Axios.post(this.url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: event => this.updateProgress(event, file)
        });

        file.error = false;
        file.success = true;
      } catch (e) {
        file.error = true;
        file.success = false;
      }
    },

    async retry(idx) {
      await this.uploadFile(this.files[idx]);
    },

    updateProgress(event, file) {
      const percentCompleted = Math.floor((event.loaded * 100) / event.total);
      file.progress = percentCompleted;
    },

    async deleteFile(idx) {
      const file = this.files[idx];

      if (file.remote) {
        await this.deleteRemoteFile(file);
      }

      this.$delete(this.files, idx);
    },

    async deleteRemoteFile(file) {
      const name = file.source.name;
      return await Axios.get(`${API_URL}?name=${name}`);
    },

    preloadFiles() {}
  }
};
</script>

<style lang="scss" scoped>
.uploader {
  border: 2px dashed $grey50;
  border-radius: 6px;
  max-height: 280px;
  overflow-y: auto;

  .files-container {
    padding: 8px;

    .files-info {
      display: flex;
      align-items: center;
      justify-content: center;

      > * {
        margin-right: 8px;
      }

      h1 {
        @include h2;
        color: $notBlack;
      }

      .add-more {
        height: 24px;

        span {
          display: block;
          @include text-body;
        }
      }
    }

    .files {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
      grid-column-gap: auto;
      grid-row-gap: 10px;
      justify-content: space-between;

      .file {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 8px;

        .image-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 140px;

          .image {
            min-width: 50%;
            max-height: 90%;
            max-width: 90%;
          }
        }

        .filename {
          @include text-body;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 12px; /* fallback */
          max-height: calc(12px * 2);
          max-width: 100%;
          color: $grey70;
        }

        .delete-button {
          margin-top: 12px;
        }

        .progress {
          position: absolute;
          top: 50%;
          width: 80px;
          height: 20px;
        }

        .statusx {
          position: absolute;
          top: 25%;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>
