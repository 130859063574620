import Moment from 'moment';

const defaultPeriods = () => {
  let end = Moment().endOf('day');
  return [
    {
      label: 'Last day',
      value: 'day',
      range: {
        start: Moment().startOf('day'),
        end
      }
    },
    {
      label: 'Last 7 days',
      value: 'week',
      range: {
        start: Moment(end).subtract(1, 'week'),
        end
      }
    }, {
      label: 'Last 2 weeks',
      value: 'halfmonth',
      range: {
        start: Moment(end).subtract(2, 'weeks'),
        end
      }
    },
    {
      label: 'Last month',
      value: 'month',
      range: {
        start: Moment(end).subtract(1, 'month'),
        end
      }
    },
    {
      label: 'Last year',
      value: 'year',
      range: {
        start: Moment(end).subtract(1, 'year'),
        end
      }
    }
  ]
}

const periodToISOString = (periodValue) => {
  const periods = defaultPeriods().map((period) => period.label);

  let periodStart;
  let periodEnd;
  switch (periodValue) {
  case periods[0]: {
    periodStart = Moment().subtract(1, 'days').startOf('day').format();
    break;
  }

  case periods[1]: {
    periodStart = Moment().subtract(7, 'days').startOf('day').format();
    break;
  }

  case periods[2]: {
    periodStart = Moment().subtract(14, 'days').startOf('day').format();
    break;
  }

  case periods[3]: {
    periodStart = Moment().subtract(1, 'months').startOf('day').format();
    break;
  }

  case periods[4]: {
    periodStart = Moment().subtract(1, 'year').startOf('day').format();
    break;
  }
  }

  if (periodStart) {
    periodEnd = Moment().format();
  }

  return { periodStart, periodEnd };
}

const periodToTimestamp = (period) => {
  if (!period || !period.value) {
    return null
  }

  let start = null;
  let end = Moment().endOf('day');

  switch (period.value) {
  case 'day':
    start = Moment().startOf('day')
    break;

  case 'week':
    start = Moment(end).subtract(1, 'week')
    break;

  case 'halfmonth':
    start = Moment(end).subtract(2, 'weeks')
    break;

  case 'month':
    start = Moment(end).subtract(1, 'month')
    break;

  case 'year':
    start = Moment(end).subtract(1, 'year')
    break;
  }

  return {
    period: period.value,
    timestamp__gte: start ? start.toISOString() : null,
    timestamp__lte: end ? end.toISOString() : null
  };
}

const rangeToTimestamp = (range) => {
  if (!range) {
    return null;
  }

  return {
    timestamp__gte: range.start ? range.start.toISOString() : null,
    timestamp__lte: range.end ? range.end.toISOString() : null
  };
}

export { defaultPeriods, periodToTimestamp, periodToISOString, rangeToTimestamp };