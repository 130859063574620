<template>
  <div class="settings-api" />
</template>

<script>
export default {
  name: 'SettingsApi'
};
</script>

<style lang="scss" scoped>
.settings-api {
}
</style>
