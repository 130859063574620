import Vue from 'vue';
import DeviceGroupsApi from '@/api/device_groups';
import DeviceGroupModel from '@/store/models/device_group';
import ResponseHandler from '@/helpers/responseHandler';

import {
  formatServices,
  // convertCharacteristics,
  buildServices
} from '@/helpers/deviceGroup';

export default {
  namespaced: true,

  state: {
    item: null,
    oldItem: null,
    meta: {},
    loading: false,
    error: null,
    params: {}
  },

  actions: {
    async getItem(ctx, id) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const { data, meta } = await DeviceGroupsApi.getItem(id);
        const item = ResponseHandler.getOnlyElementOfList(data);
        const model = new DeviceGroupModel(item);

        model.alt_services = formatServices(model.services);

        ctx.commit('setItem', model);
        ctx.commit('setMeta', meta);
      } catch (error) {

        ctx.dispatch('errors/pushError', { id: 'device_groups', error }, { root: true });
        ctx.commit('setError', error);
        throw error;

      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async updateItem(ctx, obj) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        let copy = JSON.parse(JSON.stringify(obj));
        copy.services = buildServices(obj.alt_services);

        let newItem = new DeviceGroupModel({
          ...ctx.getters.item,
          ...copy
        });

        const { data, meta } = await DeviceGroupsApi.updateItem(
          newItem.blockchain_uid,
          newItem.dto
        );

        const item = ResponseHandler.getOnlyElementOfList(data);

        const model = new DeviceGroupModel(item);

        model.alt_services = formatServices(model.services);

        ctx.commit('setItem', model);

        ctx.commit('setMeta', meta);

      } catch (error) {

        // ctx.dispatch("errors/pushError", { id: "device_groups", error }, { root: true });
        ctx.commit('setError', error);

        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async createItem(ctx, obj) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        const newItem = new DeviceGroupModel({
          ...ctx.getters.item,
          ...obj
        });

        const { data, meta } = await DeviceGroupsApi.createItem(
          newItem.dto
        );

        const item = ResponseHandler.getOnlyElementOfList(data);
        const model = new DeviceGroupModel(item);
        ctx.commit('setItem', model);
        ctx.commit('setMeta', meta);

      } catch (error) {

        ctx.dispatch('errors/pushError', { id: 'device_groups', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },


    async deleteItem(ctx, uid) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        await DeviceGroupsApi.deleteItem(uid);

        // ctx.commit("setItem", model);
        // ctx.commit("setMeta", meta);
      } catch (error) {
        // ctx.dispatch("errors/pushError", { id: "device_groups", error }, { root: true });
        ctx.commit('setError', error);

        throw error;

      } finally {
        ctx.commit('setLoading', false);
      }
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setItem(state, data) {


      // TODO: Test if that code needed 
      // if (data.alt_services) {
      //   for (let service of data.alt_services) {
      //     service.disableEditing = false;
      //   }
      // }

      state.item = data;
    },

    disableServices(state, data) {
      for (let service of state.item.alt_services) {
        service.disableEditing = true;
      }

      state.item.alt_services[data.index].editing = false;
    },

    enableServices(state) {
      for (let service of state.item.alt_services) {
        service.disableEditing = false;
      }
    },

    setService(state, data) {
      Vue.set(state.item.alt_services, data.index, data.service);
    },

    setServiceName(state, data) {
      state.item.alt_services[data.index].name = data.name;
    },

    pushService(state, service) {
      state.item.alt_services.push(service);
    },

    addService(state) {
      state.item.alt_services.unshift({
        new: true,
        name: '',
        characteristics: [{ name: '', tags: [] }]
      });
    },

    removeService(state, index) {
      state.item.alt_services.splice(index, 1);
    },

    setCharacteristic(state, data) {
      let characteristics = state.item.alt_services[data.serviceIndex].characteristics
      Vue.set(characteristics, data.charIndex, data.char);
    },

    addCharacteristic(state, data) {
      state.item.alt_services[data.index]
        .characteristics.unshift({ name: '', tags: [] });
    },

    removeCharacteristic(state, data) {
      state.item.alt_services[data.serviceIndex]
        .characteristics.splice(data.charIndex, 1);
    }
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    item: state => state.item,
    services: state =>
      state.item && state.item.services ? state.item.services : [],
    alt_services: state =>
      state.item && state.item.alt_services ? state.item.alt_services : [],
    service: state => index => state.item.alt_services(index)
  }
};
