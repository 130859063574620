import ConstantsApi from '@/api/constants';

export default {
  namespaced: true,

  state: {
    list: null,
    meta: {},
    loading: false,
    error: null,
    params: {}
  },

  actions: {
    async getConstants(ctx) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        const { data, meta } = await ConstantsApi.getList();
        ctx.commit('setList', data);
        ctx.commit('setMeta', meta);
      } catch (error) {
        console.log('ERROR', error);

        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setList(state, data) {
      state.list = data;
    }
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    list: state => state.list,
    settings: state => (state.list ? state.list[0] : null)
  }
};
