
function arrayEquals(a, b) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}

const validateJSONSchema = (arr) => {
  if (!Array.isArray(arr)) {
    throw new Error('JSON must be array');
  }
  const requiredFields = {
    uid: 'string',
    name: 'string',
    status: 'number'
  }
  for (let arrItem of arr) {
    if (!arrayEquals(Object.keys(requiredFields), Object.keys(arrItem))) {
      throw new Error('Wrong json file');
    }
    for (const key in arrItem) {
      if (typeof arrItem[key] !== requiredFields[key]) {
        throw new Error('Wrong json file');
      }
    }
  }
  return arr;
}


/**
 * Validate sensors json
 * @param {Object[]} arr - Array of sensors ( { uid, name, status })
 * @returns {Object[]}
 */
const validate = (arr) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(validateJSONSchema(arr));
    } catch (error) {
      console.log(error);
      reject(error)
    }
  });
}



export {
  validate
}