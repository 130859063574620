<template>
  <div class="heading-block">
    <h1
      class="heading"
      :class="{ bordered }"
    >
      {{ title }}
    </h1>
    <div
      v-if="hasContainer"
      class="container"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    bordered: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasContainer() {
      return !!this.$slots.default;
    },
  },
};
</script>

<style lang="scss" scoped>
.heading-block {
  display: contents;

  .heading {
    @include text-body;
    font-weight: 500;
    color: $notBlack;

    &.bordered {
      @include text-tiny;
      border-bottom: 1px solid $grey50;
      padding: 6px 0;
    }
  }

  .container {
    // padding: 5px;
    margin-top: 16px;
    overflow-y: scroll;
  }
}
</style>