<template>
  <moe-block
    heading="Balance changes"
    class="balance-changes-block"
    :transparent="true"
    :loading="loading"
  >
    <template slot="actions">
      <select-input
        v-model="tariffsTypes.value"
        class="plans-filter"
        placeholder="Select plan"
        :options="tariffsTypes.options"
        :searchable="false"
        :allow-empty="false"
      >
        <template #single-label>
          {{ tariffsTypes.value.name }}
        </template>
        <template #option="option">
          {{ option.option.name }}
        </template>
      </select-input>
    </template>

    <div
      slot="content"
      class="balance-changes-content"
    >
      <div class="configuration-strip">
        <select-input
          v-model="years.value"
          class="years-filter"
          placeholder="Select year"
          :options="years.options"
          :searchable="false"
          :allow-empty="false"
          :disabled="years.options.length <= 1"
        />
        <segmented-control
          v-model="segments.value"
          :segments="segments.options"
        />
      </div>

      <slot />
    </div>
  </moe-block>
</template>

<script>
import Moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BalanceChangesBlock',
  data() {
    const allMonths = Moment.monthsShort().map((v, idx) => {
      return {
        id: idx,
        name: v,
      };
    });

    const currentMonth = Moment().format('MMM');
    const currentValue = allMonths.find((item) => item.name === currentMonth);

    return {
      // TODO: move to one VUEX place
      tariffsTypes: {
        value: { name: 'All plans', value: null },
        options: [
          { name: 'All plans', value: null },
          { name: 'Per transition', value: 'TARIF_PER_TRX' },
          { name: 'Per sensor', value: 'TARIF_PER_SENSOR' },
          { name: 'Per sensor smart', value: 'TARIF_PER_SENSOR_SMART' },
        ],
      },

      years: {
        value: null,
        options: [],
      },

      segments: {
        value: currentValue,
        options: allMonths,
      },
    };
  },
  computed: {
    ...mapGetters({
      params: 'finances/params',
      filtersRanges: 'finances/filtersRanges',
      loading: 'finances/loading',
    }),
  },

  watch: {
    'segments.value': function (/*val, old*/) {
      // if (old) {
      //   let url = new URL(window.location);
      //   let params = new URLSearchParams(url.search);
      //   params.set("month", val.id);
      //   this.$router.push({ path: `${window.location.pathname}?${params}` });
      //   this.changeParams({ month: parseInt(val.id) + 1 });
      //   this.getList();
      // }
    },

    'years.value': function (/*val, old*/) {
      // if (old) {
      //   this.changeParams({ year: val });
      //   this.getList();
      // }
    },

    'tariffsTypes.value': function (/*val*/) {
      // this.changeParams({ tarif: val.value });
      // this.getList();
    },
  },

  beforeMount() {
    // this.getFilters().then(() => {
    //   this.initYears();
    //   this.setCurrentMonth();
    //   this.changeParams({
    //     tarif: this.tariffsTypes.value.value,
    //     year: this.years.value,
    //     month: parseInt(this.segments.value.id) + 1,
    //   });
    //   this.getList();
    // });
  },
  methods: {
    ...mapActions({
      getList: 'finances/getList',
      getFilters: 'finances/getFilters',
      changeParams: 'finances/changeParams',
    }),

    setCurrentMonth() {
      let url = new URL(window.location);
      let params = new URLSearchParams(url.search);
      let monthId = params.get('month');

      if (typeof monthId !== 'undefined' && monthId != null) {
        monthId = parseInt(monthId);
        this.segments.value = this.segments.options.find((segment) => {
          return segment.id == monthId;
        });
        return;
      }

      const currentMonth = Moment().format('MMM');
      this.segments.value = this.segments.options.find(
        ({ name }) => name === currentMonth
      );
    },

    initYears() {
      const min = this.filtersRanges.year.min;
      const max = this.filtersRanges.year.max;
      const diff = max - min;

      this.years.value = max;

      let i = 0;
      do {
        this.years.options.push(min + i);
        i++;
      } while (i <= diff);
    },
  },
 



  
};
</script>

<style lang="scss" scoped>
.balance-changes-block {
  .plans-filter {
    width: 150px;
    max-width: 150px;
  }

  .balance-changes-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100%;
    max-height: 100%;
  }

  .configuration-strip {
    display: flex;
    align-items: center;

    .years-filter {
      max-width: 100px;
      margin-right: 8px;
    }
  }
}
</style>
