import Vue from 'vue';
import app from '@/App.vue';
import store from '@/store';
import router from '@/router';

// import mapbox from "mapbox-gl";
// window.mapboxgl = mapbox;

(async () => {
  window.mapboxgl = (await import( /* webpackPrefetch: true */ 'mapbox-gl')).default;
})();


import './prefetchData';

import '@/styles/base.scss';
import '@moeco-platform/moeco-components/dist/moeco-components.css';
// import "@moeco-platform/moeco-components/src/styles/base.scss";
import '@moeco-platform/moeco-components/src/assets/fonts/stylesheet.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import VueWaypoint from 'vue-waypoint';
Vue.use(VueWaypoint);

import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
Vue.use(VueVirtualScroller);

import VueToast from 'vue-toast-notification';
Vue.use(VueToast, {
  position: 'top'
});

// import HighchartsVue from 'highcharts-vue'
// Vue.use(HighchartsVue);

import 'v-title/lib/element-ui';
import VTitle from 'v-title';
Vue.use(VTitle);

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

// import VueClipboard from "vue-clipboard2";
// Vue.use(VueClipboard);

// import VueGeolocation from 'vue-browser-geolocation';
// Vue.use(VueGeolocation);

import { plugins, filters } from '@moeco-platform/clients';

// Retrieve authorization token 
import Cookie from 'cookie_js';
const apiKey = (process.env.VUE_APP_DEBUG_AUTH_TOKEN) ? process.env.VUE_APP_DEBUG_AUTH_TOKEN : Cookie.get('token');

import Request from '@/helpers/request';

Request.setToken(apiKey);

Vue.use(
  plugins.auth,
  {
    apiKey,
    baseUrl: process.env.VUE_APP_AUTH_API,
    store
  }
);

Vue.use(
  plugins.notifications,
  {
    apiKey,
    baseUrl: process.env.VUE_APP_NOTIFICATIONS_SERVICE_API_URL,
    store
  }
);

Vue.use(plugins.checkPermission, { store });

Vue.use(plugins.dragndropPrevent);

Vue.filter('cutnumber', filters.cutnumber);

import {
  // MoeBlock,
  HeadingBlock,
  InfoBlock,
  ValuesBlock
} from '@/components/Blocks';


Vue.component('InfoBlock', InfoBlock);
Vue.component('HeadingBlock', HeadingBlock);
Vue.component('ValuesBlock', ValuesBlock);

import Popup from '@/components/Popups/Popup';
import Preloader from '@/components/Preloader';


import LabeledInput from '@/components/Inputs/LabeledInput';

import {
  TextInput, SelectInput,
  ButtonInput, CheckboxInput,
  /* LabeledInput, */ RadioInput,
  SwitchInput, SegmentedControl,
  SearchInput, PasswordInput, MoeBlock
} from '@moeco-platform/moeco-components';

import BackButton from '@/components/Inputs/BackButton';

Vue.component('MoeBlock', MoeBlock);
Vue.component('Popup', Popup);
Vue.component('Preloader', Preloader);
Vue.component('TextInput', TextInput);
Vue.component('SelectInput', SelectInput);
Vue.component('ButtonInput', ButtonInput);
Vue.component('SearchInput', SearchInput);
Vue.component('SwitchInput', SwitchInput);
Vue.component('CheckboxInput', CheckboxInput);
Vue.component('SegmentedControl', SegmentedControl);
Vue.component('RadioInput', RadioInput);
Vue.component('BackButton', BackButton);
Vue.component('LabeledInput', LabeledInput);
Vue.component('PasswordInput', PasswordInput);

import Uploader from '@/components/Inputs/Uploader/Uploader';
Vue.component('Uploader', Uploader);

(async () => {
  const Highcharts = (await import( /* webpackPrefetch: true */ 'highcharts-vue'));
  Vue.component('Highcharts', Highcharts.Chart);
})();

import { debounce } from 'debounce';
Vue.use(debounce);

new Vue({
  store,
  router,
  render: h => h(app)
}).$mount('#app');

