<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2426 6.34315L12 10.5858L7.75735 6.34315C7.56981 6.15561 7.31546 6.05025 7.05024 6.05025C6.78502 6.05025 6.53067 6.15561 6.34313 6.34315C6.1556 6.53068 6.05024 6.78504 6.05024 7.05025C6.05024 7.31547 6.1556 7.56982 6.34313 7.75736L10.5858 12L6.34313 16.2426C6.1556 16.4302 6.05024 16.6845 6.05024 16.9497C6.05024 17.215 6.1556 17.4693 6.34313 17.6569C6.53067 17.8444 6.78502 17.9497 7.05024 17.9497C7.31546 17.9497 7.56981 17.8444 7.75735 17.6569L12 13.4142L16.2426 17.6569C16.4302 17.8444 16.6845 17.9497 16.9497 17.9497C17.215 17.9497 17.4693 17.8444 17.6568 17.6569C17.8444 17.4693 17.9497 17.215 17.9497 16.9497C17.9497 16.6845 17.8444 16.4302 17.6568 16.2426L13.4142 12L17.6568 7.75736C17.8444 7.56982 17.9497 7.31547 17.9497 7.05025C17.9497 6.78504 17.8444 6.53068 17.6568 6.34315C17.4693 6.15561 17.215 6.05025 16.9497 6.05025C16.6845 6.05025 16.4302 6.15561 16.2426 6.34315Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'CrossIcon',

  props: {
    fill: {
      type: String,
      default: '#474B6D'
    }
  }
};
</script>