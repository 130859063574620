export default class ResponseHandler {
  static getOnlyElementOfList(list) {
    if (!Array.isArray(list)) throw Error('Passed value is not an array')

    if (list.length > 1) throw Error('The array has more than one item')

    if (list.length === 0) throw Error('The array has no items');

    return list[0]
  }
}
