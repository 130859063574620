import DeviceGroupsApi from '@/api/device_groups';
import InlineChartsCacheFactory from '@/store/helpers/inlineChartsCache';

const cacheHelpers = new InlineChartsCacheFactory();

import { template } from '@/store/helpers/lazyloading'

const state = {
  namespaced: true,

  state: {
    ...cacheHelpers.state,
    list: [],
    meta: {},
    loading: false,
    error: null,
    params: {
      term: null,
      orderBy: {
        value: 'updated_at',
        desc: true
      },
      limit: {
        count: 20,
        offset: 0
      },
    },
  },

  actions: {
    async getList(ctx) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const { data, meta } = await DeviceGroupsApi.getList(ctx.getters.params);
        ctx.commit('setList', data);

        // This mutation is from helpers;
        ctx.commit('fillCachedList', data);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'device_groups', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },



    // Lazy loading
    getLazyList(ctx) {
      return template('getList', 'List', DeviceGroupsApi)(ctx);
    },

    resetLazyList(context) {
      context.commit('resetLazyList');
    },

    //// 


    changeParams(context, params) {
      if (params.orderBy) {
        if (context.state.params.orderBy.value === params.orderBy.value) {
          params.orderBy.desc = !context.state.params.orderBy.desc;
        } else {
          params.orderBy.desc = true;
        }
      }
      context.commit('changeParams', params);
    },

    ...cacheHelpers.actions
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setList(state, data) {
      state.list = data;
    },

    // Lazy loading
    addToList(state, data) {
      state.list = [...state.list, ...data];
    },
    
    resetLazyList(state) {
      state.list = [];
      state.params.limit.offset = 0;
      state.params.limit.count = 20;
    },
    
    setMetaList(state, meta) {
      state.listMeta = meta;
    },
    
    /////

    changeParams(state, data) {
      Object.assign(state.params, data);
    },
    ...cacheHelpers.mutations
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    list: state => (state.list ? state.list : []),
    params: state => state.params,
    ...cacheHelpers.getters
  }
};

export default state;