<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons / check-mark">
      <path
        stroke-width="1.75"
        stroke-miterlimit="10"
        class="checkmark-path"
        d="M4.7,10.7L4.7,10.7c5,5,5,5,5,5l10-10l0,0l0,0"
        :stroke="fill"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CheckmarkIcon',
  props: {
    fill: {
      default: '#FFFFFF',
      type: String
    }
  }
};
</script>
