<template>
  <ul class="navigation-panel">
    <router-link
      v-for="route in routes"
      :key="route.icon"
      :to="route.route"
      tag="li"
      :class="route.activeClass"
    >
      <component
        :is="route.icon"
        v-if="route.icon"
      />
      <span>{{ route.label }}</span>
    </router-link>
  </ul>
</template>

<script>
import DataIcon from '@/components/Icons/Data';
import DevicesIcon from '@/components/Icons/Devices';
import GatewaysIcon from '@/components/Icons/Gateways';
import FinancesIcon from '@/components/Icons/Finances';
import DashboardIcon from '@/components/Icons/Dashboard';

const devicesRoute = 2;
const gatewaysRoute = 3;

export default {
  name: 'NavigationPanel',

  components: {
    DataIcon,
    DevicesIcon,
    FinancesIcon,
    DashboardIcon,
    GatewaysIcon,
  },

  data() {
    return {
      routes: [
        {
          route: { name: 'dashboard' },
          icon: 'dashboard-icon',
          label: 'Dashboard',
          show: true
        },
        {
          route: { name: 'data' },
          icon: 'data-icon',
          label: 'Data',
          show: true
        },
        {
          route: { name: 'device-groups' },
          icon: 'devices-icon',
          label: 'Sensors',
          activeClass: '',
          show: false
        },
        {
          route: { name: 'gateway-groups' },
          icon: 'gateways-icon',
          label: 'Gateways',
          activeClass: '',
          show: true
        },
        {
          route: { name: 'finances' },
          icon: 'finances-icon',
          label: 'Finances',
          show: true
        },
        // {
        //   route: { name: "admin" },
        //   label: "Admin",
        //   show: true
        // }
      ]
    };
  },

  computed: {
    // ...mapGetters({
    //   ifAdmin: "auth/ifAdmin"
    // }),

    currentRoute() {
      return this.$route.name;
    }
  },

  watch: {
    // ifAdmin: {
    //   immediate: true,
    //   handler: function() {
    //     this.routes[5].show = this.ifAdmin;
    //   }
    // },

    currentRoute: function() {
      this.handleRoutesActiveClass();
    }
  },

  methods: {
    handleRoutesActiveClass() {
      const deviceGroupRoutes = ['services', 'devices', 'settings'].map(
        r => 'device-group-' + r
      );

      const gatewayGroupRoutes = ['gateways', 'settings'].map(
        r => 'gateway-group-' + r
      );

      const allowed = [...deviceGroupRoutes, ...gatewayGroupRoutes];

      let routeIndx = null;

      if (this.currentRoute.includes('device-group')) {
        routeIndx = devicesRoute;
      }

      if (this.currentRoute.includes('gateway-group')) {
        routeIndx = gatewaysRoute;
      }

      this.routes[devicesRoute].activeClass = '';
      this.routes[gatewaysRoute].activeClass = '';

      if (
        (routeIndx && allowed.includes(this.currentRoute)) ||
        this.currentRoute.includes('device-groups') ||
        this.currentRoute.includes('gateway-groups')
      ) {
        this.routes[routeIndx].activeClass = 'router-link-active';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation-panel {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  li {
    @include text-small;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    color: $darkBlue;
    opacity: 0.2;
    cursor: pointer;
    font-weight: 500;

    & ~ li {
      margin-top: 32px;
    }

    &.router-link-active {
      opacity: 1;
    }
  }
}
</style>
