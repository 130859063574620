<template>
  <div
    class="labeled-input"
    :class="{ ...classes, error: hasErrors }"
    :disabled="disabled"
    @click="focusInput"
  >
    <label :class="{ error: classes.filled && hasErrors }">
      {{ placeholder }}
    </label>

    <input
      v-if="!priceInput"
      ref="input"
      :type="type"
      :value="query"
      :autocomplete="autocomplete"
      :readonly="readonly"
      @input="handleInput"
      @focus="onFocus"
      @blur="onBlur"
      @keypress.enter="onEnterPress"
    >

    <input
      v-if="priceInput"
      ref="input"
      v-imask="mask"
      :type="type"
      :autocomplete="autocomplete"
      :readonly="readonly"
      @accept="onAccept"
      @focus="onFocus"
      @blur="onBlur"
      @keypress.enter="onEnterPress"
    >

    <!--    @input="handleInput" -->
  </div>
</template>

<script>
import { IMaskDirective } from 'vue-imask';

export default {
  directives: { imask: IMaskDirective },

  props: {
    type: {
      type: String,
      default: 'text',
    },

    validation: {
      type: String,
      default: '',
    },

    hasErrors: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    priceInput: {
      type: Number,
      default: null
    },

    autocomplete: {
      type: String,
      default: null
    },

    readonly: {
      type: Boolean,
    },
  },

  data() {
    return {
      focused: false,
      query: '',

      mask: {
        mask: Number,
        scale: 2,
        signed: false,
        thousandsSeparator: '',
      },
    };
  },

  computed: {
    classes() {
      return {
        focused: this.focused,
        filled: this.query !== '',
      };
    },

    hasIcon() {
      return !!this.$slots.icon;
    },

    // hasErrors() {
    //   if (this.validation == "email") {
    //     if (this.query == "") return false;
    //
    //     return !EmailValidator.validate(this.query);
    //   }
    // }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.query = this.value;
      },
    },
  },

  mounted() {
    this.$emit('input', this.$refs.input.value);
  },

  methods: {
    onFocus() {
      this.focused = true;
    },

    onBlur() {
      this.focused = false;
    },

    focusInput() {
      this.$refs.input.focus();
    },

    handleInput(e) {
      this.query = e.target.value;
      this.$emit('input', this.query, e);
    },

    onAccept(e) {
      const maskRef = e.detail;
      this.query = maskRef.value;
      this.$emit('input', this.query, e);
    },

    onEnterPress() {
      console.log('event');
      this.$emit('enterPress');
    },
  },
};
</script>

<style lang="scss" scoped>
.labeled-input-container {
}
.labeled-input {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  border: 1px solid $grey50;
  border-radius: 6px;
  transition: $defaultTransition;
  outline: none;
  padding: 2px 8px;
  background-color: $white;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  position: relative;

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }

  input {
    @include text-body;
    background-color: transparent;
    border: none;
    width: 100%;
    outline: none;
    padding: 0;
    height: 0;
    box-sizing: border-box;
    color: $notBlack;
    height: 20px;
  }

  label {
    @include text-body;
    color: $grey50;
    height: 18px;
    transition: $defaultTransition;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    &.error {
      color: $accentRed;
    }
  }

  input::placeholder {
    color: $grey50;
  }

  &:hover {
    border-color: $grey50;
    box-shadow: $defaultShadow;

    .icon {
      color: $accentBlue;
    }

    input {
      color: $accentBlue;
    }
  }

  &.error {
    border: 1px solid $accentRed;
  }

  &:hover &.error {
    border: 1px solid $accentRed;
  }

  &.filled {
    label {
      @include text-tiny;
      height: 10px;
      top: -20px;
    }
  }

  &.focused {
    box-shadow: $defaultShadow;

    label {
      @include text-tiny;
      height: 10px;
      top: -20px;
    }
  }

  @-webkit-keyframes autofill {
    to {
      color: inherit;
      background: transparent;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    -webkit-box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: $notBlack;
  }
}
</style>
