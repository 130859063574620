<template>
  <div
    v-if="activity"
    class="blocks-grid"
  >
    <info-block borderless>
      <template #title>
        {{ activity.active }}
      </template>
      <template #descr>
        Active
      </template>
    </info-block>
    <info-block
      borderless
      tint="red"
    >
      <template #title>
        {{ activity.inactive }}
      </template>
      <template #descr>
        Down
      </template>
    </info-block>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      activity: 'deviceGroupStat/activity/item',
    }),
  },
  async activated() {
    const deviceGroupUid = this.$route.params.id;

    try {
      await this.getActivity({ id: deviceGroupUid });
    } catch (error) {
      this.$toast.error(error, {
        position: 'top-left',
        duration: 6000,
      });
    }
  },
  deactivated() {
    this.setActivity(null);
  },

  methods: {
    ...mapActions({
      getActivity: 'deviceGroupStat/activity/getItem',
    }),
    ...mapMutations({
      setActivity: 'deviceGroupStat/activity/setItem',
    }),
  },
};
</script>

<style lang="scss">
</style>
