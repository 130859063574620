<template>
  <div class="uploader-content">
    <div class="files-info">
      <h1>Total {{ files.length }} files.</h1>
      <button-input
        :primary="false"
        class="add-more"
        @click.native="addFiles"
      >
        <span>Add more</span>
      </button-input>
    </div>
    <div class="files">
      <div
        v-for="(file, idx) in files"
        :key="idx"
        class="file"
        @click.stop
      >
        <div class="image-wrapper">
          <img
            v-if="isImage(file)"
            :src="file.data"
            class="image"
          >
        </div>
        <span class="filename">{{ file.source.name }}</span>
        <button-input
          class="delete-button"
          @click.native="deleteFile(idx)"
        >
          Delete
        </button-input>
        <progress-bar
          :progress="file.progress"
          class="progress"
        />
        <status
          :success="file.success"
          :error="file.error"
          class="statusx"
          @retry="retry(idx)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from './Progress';
import Status from './Status';

export default {
  components: {
    ProgressBar,
    Status
  },

  props: {
    files: {
      type: Array,
      required: true
    }
  },

  methods: {
    isImage(file) {
      return (
        file.source.type === 'image/png' || file.source.type === 'image/jpeg'
      );
    },

    addFiles() {
      this.$emit('addFiles');
    },

    deleteFile(idx) {
      this.$emit('deleteFile', idx);
    },

    retry(idx) {
      this.$emit('retry', idx);
    }
  }
};
</script>

<style lang="scss" scoped>
.uploader-content {
  padding: 8px;

  .files-info {
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
      margin-right: 8px;
    }

    h1 {
      @include h2;
      color: $notBlack;
    }

    .add-more {
      height: 24px;

      span {
        display: block;
        @include text-body;
      }
    }
  }

  .files {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    grid-column-gap: auto;
    grid-row-gap: 10px;
    justify-content: space-between;

    .file {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 8px;

      .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 140px;

        .image {
          min-width: 50%;
          max-height: 90%;
          max-width: 90%;
        }
      }

      .filename {
        @include text-body;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 12px; /* fallback */
        max-height: calc(12px * 2);
        max-width: 100%;
        color: $grey70;
      }

      .delete-button {
        margin-top: 12px;
      }

      .progress {
        position: absolute;
        top: 50%;
        width: 80px;
        height: 20px;
      }

      .statusx {
        position: absolute;
        top: 25%;
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>