import chart from './data/chart';
import metainfo from './data/metainfo';

export default {
  namespaced: true,
  modules: {
    chart,
    metainfo
  }
}
