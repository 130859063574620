<template>
  <div class="details-heading">
    <search-input class="search-input" />

    <select-input
      v-model="tarifFilter"
      class="plan-type-filter"
      placeholder="Select plan"
      :options="tariffsTypes.options"
      :searchable="false"
      :allow-empty="false"
    >
      <template #single-label>
        {{ tariffsTypes.value.name }}
      </template>
      <template #option="option">
        {{ option.option.name }}
      </template>
    </select-input>

    <calendar-popup
      class="period-filter"
      v-bind="calendar"
      :shortcuts="periodOptions"
      :default="calendar.default"
      @close="closeCalendar"
      @range="rangeChanged"
    />

    <button-input
      :primary="false"
      linear
      class="period-filter"
      @click.native="openCalendar"
    >
      <calendar-icon
        slot="icon"
        fill="currentColor"
        class="calendar"
      />
      <template #default>
        <span>{{ rangeLabel }}</span>
      </template>
    </button-input>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import CalendarPopup from '@/components/Popups/Calendar/CalendarPopup';
import CalendarIcon from '@/components/Icons/Calendar';

export default {
  name: 'DetailsHeading',
  components: { CalendarIcon, CalendarPopup },
  data() {
    return {
      rangeLabel: '',
      // TODO: move to one VUEX place
      tariffsTypes: {
        value: { name: 'All plans', value: null },
        options: [
          { name: 'All plans', value: null },
          { name: 'Per transition', value: 'TARIF_PER_TRX' },
          { name: 'Per sensor', value: 'TARIF_PER_SENSOR' },
          { name: 'Per sensor smart', value: 'TARIF_PER_SENSOR_SMART' },
        ],
      },

      calendar: {
        width: 480,
        opened: false,
        arrowUp: true,
        assignTo: null,
        default: null,
      },

      deviceGroups: {
        options: [],
        value: { name: 'All device groups' },
      },

      period: {
        //TODO: user periods helper
        options: [
          'Last day',
          'Last 7 days',
          'Last 2 weeks',
          'Last month',
          'Last year',
        ],
        value: 'Last 7 days',
      },
    };
  },
  computed: {
    ...mapGetters({
      params: 'financesDetails/params',
      deviceGroupsList: 'deviceGroups/list',
      periodOptions: 'transactions/periods',
    }),

    rangeFilter: {
      get() {
        return this.params.range;
      },
      set(/*range*/) {
        // this.changeParams({
        //   timestamp__gte: range.start.toISOString(),
        //   timestamp__lte: range.end.toISOString()
        // });
        // this.getList();
      },
    },

    tarifFilter: {
      get() {
        return this.tariffsTypes.value;
      },

      set(/*tarif*/) {
        // this.tariffsTypes.value = tarif;
        // this.changeParams({ tarif: tarif.value });
        // this.getList();
      },
    },
  },
  created() {
    this.changeParams({
      filter: 'all',
      timestamp__gte: null,
      timestamp__lte: null,
    });

    if (this.$route.params.date) {
      const start = Moment(this.$route.params.date).startOf('day');
      const end = Moment(this.$route.params.date).endOf('day');
      this.rangeFilter = { start, end };
      this.rangeLabel = start.format('MMMM D');
      this.calendar.default = { range: this.rangeFilter };
    } else {
      this.rangeFilter = this.periodOptions[0].range;
      this.rangeLabel = this.periodOptions[0].label;
      this.calendar.default = this.periodOptions[0];
    }
  },

  methods: {
    ...mapActions({
      getList: 'financesDetails/getList',
      changeParams: 'financesDetails/changeParams',
    }),

    rangeChanged(range) {
      // TODO: move to watch
      this.rangeLabel = range.label ? range.label : 'Some period';
      this.rangeFilter = range;

      // TODO: check if there are some errors
      this.calendar.opened = false;
    },

    openCalendar(ev) {
      Object.assign(this.calendar, {
        opened: true,
        assignTo: ev.currentTarget,
      });
    },

    closeCalendar() {
      this.calendar.opened = false;
    },
  },


 
};
</script>

<style lang="scss" scoped>
.details-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-input {
    max-width: 300px;
    margin-left: 0;
    margin-right: auto;
  }

  .plan-type-filter {
    max-width: 200px;
    margin-right: 16px;
  }

  .period-filter {
    max-width: 300px;

    .calendar {
      margin-right: 8px;
    }
  }
}
</style>
