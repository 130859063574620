<template>
  <popup :opened="opened">
    <template #header>
      Devices import
    </template>

    <template #content>
      <ProgressBar
        :total="localDevices.length"
        :current="total.imported + total.fail"
      />

      <div class="totals">
        <div>
          Sensors to be uploaded: {{ localDevices.length }} /
          {{ total.imported + total.fail }}
        </div>
        <div>Total success: {{ total.imported }}</div>
        <div>Total fail: {{ total.fail }}</div>
      </div>
    </template>

    <template #actions>
      <div class="import-devices-container">
        <div class="buttons">
          <button-input
            :primary="false"
            :disabled="loading"
            @click.native="onClose"
          >
            Close
          </button-input>
          <button-input
            :disabled="!reportIsReady"
            @click.native="onExport"
          >
            Download report
          </button-input>
          <button-input
            :disabled="!showImportButton"
            @click.native="importDevices"
          >
            Import devices
          </button-input>
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import ProgressBar from '@/components/ProgressBar';
import DevicesApi from '@/api/devices';
import Moment from 'moment';

export default {
  components: { ProgressBar },

  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    devices: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      loading: false,
      reportIsReady: false,
      showImportButton: true,

      total: {
        imported: 0,
        fail: 0,
      },

      localDevices: [],
      errors: [],
    };
  },

  watch: {
    opened: function () {
      if (this.opened == true) {
        this.localDevices = JSON.parse(JSON.stringify(this.devices));

        for (let device of this.localDevices) {
          this.$set(device, 'importStatus', 'ready');
          this.$set(device, 'error', null);
        }
      }
    },
  },

  methods: {
    onClose() {
      this.localDevices = [];
      this.reset();
      this.$emit('close');
    },

    onExport(e) {
      e.preventDefault();
      const a = document.createElement('a');
      const file = new Blob([JSON.stringify(this.localDevices)], {
        type: 'text/plain',
      });
      a.href = URL.createObjectURL(file);
      a.download = `import-devices-report-${Moment().format(
        'YYYY_DD_MM_HH_mm_ss'
      )}.json`;
      a.click();
    },

    async importDevices() {
      this.reset();

      this.showImportButton = false;
      this.loading = true;

      for (let device of this.localDevices) {
        let copyDevice = JSON.parse(JSON.stringify(device));

        delete copyDevice.importStatus;
        delete copyDevice.error;

        await DevicesApi.createItem({
          device_group_uid: this.$route.params.id,
          ...copyDevice,
        })
          .then(() => {
            device.importStatus = 'success';
            this.total.imported++;
          })
          .catch((error) => {
            device.importStatus = 'error';
            device.error = error;
            this.errors.push({ device, msg: error });
            this.total.fail++;
          });
      }
      this.reportIsReady = true;
      this.loading = false;
    },

    reset() {
      this.errors = [];
      this.total.imported = 0;
      this.total.fail = 0;
      this.showImportButton = true;
      this.reportIsReady = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 32px 10px 32px 32px;
  background: white;
  .totals {
    margin-top: 10px;
  }
  .devices-import-table {
    min-height: 500px;
    max-height: 500px;
  }
}

.import-devices-container {
  display: flex;
  .buttons {
    display: flex;
    margin-left: auto;
    :nth-child(1n) {
      margin-right: 16px;
    }

    :last-child {
      margin-right: 0;
    }
  }
}
</style>
