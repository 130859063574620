<template>
  <div class="dashboard-view">
    <moe-block
      class="item"
      compressed
      heading="Data"
    >
      <div
        slot="content"
        class="wrapper"
      >
        <data-section />
      </div>

      <router-link
        slot="footer"
        to="/data"
        class="more-button"
      >
        <button-input :primary="false">
          View analitycs
        </button-input>
      </router-link>
    </moe-block>

    <moe-block
      class="item"
      compressed
      heading="Sensors"
    >
      <div
        slot="content"
        class="wrapper"
      >
        <devices-section />
      </div>
      <router-link
        slot="footer"
        to="/device_groups"
        class="more-button"
      >
        <button-input :primary="false">
          Manage sensors
        </button-input>
      </router-link>
    </moe-block>

    <moe-block
      class="item"
      compressed
      heading="Finances"
    >
      <div
        slot="content"
        class="wrapper"
      >
        <finances-section />
      </div>
      <router-link
        slot="footer"
        to="/finances"
        class="more-button"
      >
        <button-input :primary="false">
          View transactions
        </button-input>
      </router-link>
    </moe-block>

    <moe-block
      class="item"
      compressed
      paddingless
      heading="Gateways"
    >
      <div
        slot="content"
        class="wrapper"
      >
        <gateways-section />
      </div>

      <router-link
        slot="footer"
        to="/gateway_groups"
        class="more-button"
      >
        <button-input :primary="false">
          Manage gateways
        </button-input>
      </router-link>
    </moe-block>
  </div>
</template>

<script>
// import DataSection from "./views/DataSection";
// import DevicesSection from "./views/DevicesSection";
// import GatewaysSection from "./views/GatewaysSection";
// import FinancesSection from "./views/FinancesSection";

const DataSection = () =>
  import(/* webpackPrefetch: true */ './views/DataSection');
const DevicesSection = () =>
  import(/* webpackPrefetch: true */ './views/DevicesSection');
const GatewaysSection = () =>
  import(/* webpackPrefetch: true */ './views/GatewaysSection');
const FinancesSection = () =>
  import(/* webpackPrefetch: true */ './views/FinancesSection');

export default {
  name: 'DashboardView',

  components: {
    DataSection,
    DevicesSection,
    GatewaysSection,
    FinancesSection,
  },
};
</script>

<style lang="scss" scoped>
.dashboard-view {
  display: grid;
  grid-template: repeat(9, 1fr) / repeat(3, 1fr);
  grid-gap: 16px;
  height: 100%;

  .item {
    &:nth-child(1) {
      grid-row: 1 / 10;
    }

    &:nth-child(2) {
      grid-row: 1 / 10;
    }

    &:nth-child(3) {
      grid-row: 1 / 7;
    }

    &:nth-child(4) {
      grid-row: 7 / 10;
    }
  }

  .wrapper {
    display: contents;
  }

  .more-button {
    display: flex;
    flex: 1;
    text-decoration: none;

    > button {
      flex: 1;
    }
  }
}
</style>
