import AccountSettingsApi from '@/api/account';
import PersonalInfoModel from '../models/personal_info';
import SecurityModel from '../models/security';

export default {
  namespaced: true,

  state: {
    item: null,
    meta: {},
    loading: false,
    error: null,
    params: {}
  },

  actions: {
    async updatePersonalInfo(ctx, obj) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        const model = new PersonalInfoModel(obj);
        const { data, meta } = await AccountSettingsApi.updatePersonalInfo(model.dto);
        ctx.commit('setItem', data);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'account', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async updateSecurity(ctx, obj) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        const model = new SecurityModel(obj);
        const { data, meta } = await AccountSettingsApi.updateSecurity(model.dto);
        ctx.commit('setItem', data);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', error, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },


    async updateNotifications(ctx, obj) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        const { data, meta } = await AccountSettingsApi.updateNotifications(obj);
        ctx.commit('setItem', data);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', error, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setItem(state, data) {
      state.item = data;
    }
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    item: state => state.item
  }
};
