<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.3265 12.9188L12.7944 18.7006L11.5656 20L4 12L11.5656 4L12.7944 5.29936L7.3265 11.0812H20V12.9188H7.3265Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeftIcon',
  props: {
    fill: {
      default: '#474B6D',
      type: String
    }
  }
};
</script>
