<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12.9188 16.6735 5.7818-5.4679 1.2994 1.2288-8 7.5656-8-7.5656 1.29936-1.2288 5.78184 5.4679v-12.6735h1.8376z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'DepositIcon',

  props: {
    fill: {
      type: String,
      default: '#37cb95'
    }
  }
};
</script>