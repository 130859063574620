export default class PersonalInfoModel {
  constructor(obj) {
    this.password = null;
    this.new_pass = null;

    if (obj) {
      Object.assign(this, obj);
    }
  }

  get dto() {
    return {
      password: this.password ? String(this.password) : '',
      new_pass: this.new_pass ? String(this.new_pass) : ''
    };
  }
}
