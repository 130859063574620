<template>
  <div class="payment-roll">
    <payment-card
      v-for="item in items"
      :key="item.id"
      :item="item"
      class="payment-card"
    />
    <div
      class="add-payment"
      @click="openAddCard"
    >
      <plus-icon
        class="icon"
        fill="currentColor"
      />
      <span>{{ addText }}</span>
    </div>

    <add-card-popup
      :opened="addCardOpened"
      @close="closeAddCard"
      @add="addCard"
    />
  </div>
</template>

<script>
import PaymentCard from './PaymentCard';
import AddCardPopup from '@/components/Popups/AddCard/AddCardPopup';
import PlusIcon from '@/components/Icons/Plus';

export default {
  components: {
    PaymentCard,
    AddCardPopup,
    PlusIcon
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },

    addText: {
      type: String,
      default: 'Add credit card'
    }
  },

  data() {
    return {
      addCardOpened: false
    };
  },

  methods: {
    openAddCard() {
      this.addCardOpened = true;
    },

    closeAddCard() {
      this.addCardOpened = false;
    },

    addCard(card) {
      this.closeAddCard();
      this.$emit('addCard', card)
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-roll {
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 16px;

  .payment-card {
    margin-right: 16px;
    flex-shrink: 0;
  }

  .add-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey30;
    border-radius: 6px;
    color: $grey70;
    width: 174px;
    flex-shrink: 0;
    cursor: pointer;
    transition: background-color $defaultTransition;

    .icon {
      color: $notBlack;
    }

    &:hover {
      background: $grey10;
    }
  }
}
</style>