export default class GatewayGroupModel {
  constructor(obj) {
    this.id = null;
    this.name = null;
    this.group_type = null;
    this.blockchain_uid = null;
    this.eos_name = null;
    this.status = null;
    this.use_encryption = null;
    this.use_signature = null;
    this.created_at = null;
    this.updated_at = null;

    if (obj) {
      Object.assign(this, obj);
    }
  }

  get dto() {
    return {
      name: String(this.name),
      status: Number(this.status),
      use_encryption: Number(this.use_encryption),
      use_signature: Number(this.use_signature),
      type: String(this.group_type)
    };
  }
}
