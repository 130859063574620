import data from './dashboard/data';
import devices from './dashboard/devices';
import gateways from './dashboard/gateways';
import deviceGroups from './dashboard/device_groups';
import finances from './dashboard/finances';

export default {
  namespaced: true,
  modules: {
    data,
    devices,
    gateways,
    deviceGroups,
    finances
  }
}
