import Vue from 'vue';

class InlineChartsCacheFactory {
  constructor() {
    this.state = InlineChartsCacheFactory.buildState();
    this.mutations = InlineChartsCacheFactory.buildMutations(this.state);
    this.actions = InlineChartsCacheFactory.buildActions(this.state);
    this.getters = InlineChartsCacheFactory.buildGetters(this.state);
  }

  static buildState() {
    const state = {
      chartData: {},
      cachedList: {},
    };
    return JSON.parse(JSON.stringify(state));
  }

  static buildMutations() {
    const mutations = {
      fillCachedList(state, data) {
        for (const dataItem of data) {
          if (!state.cachedList[dataItem.id]) {
            Vue.set(state.cachedList, dataItem.id, dataItem.blockchain_uid);
          }
        }
      },

      setChartData(state, { id, data }) {
        if (!state.chartData[id]) {
          Vue.set(state.chartData, id, data);
        }
      },
    }

    return mutations;
  }


  static buildActions() {
    const actions = {
      setChartData(context, payload) {
        context.commit('setChartData', payload);
      }
    }

    return actions;
  }

  static buildGetters() {
    const getters = {
      cachedList: state => state.cachedList,
      chartData: state => state.chartData,
    }

    return getters;
  }
}


export default InlineChartsCacheFactory;