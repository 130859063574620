<template>
  <div class="settings-notifications">
    <div v-if="loading">
      <div class="loader-background" />
      <preloader2 />
    </div>

    <div
      v-for="row in rows"
      :key="row.name"
      class="row"
    >
      <div class="title">
        {{ row.title }}
      </div>
      <div class="form-item">
        <div class="form-item-inner">
          <checkbox-input
            v-model="row.value.email"
            class="checkbox"
            @input="save(row)"
          />
          <span>Email</span>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-inner">
          <checkbox-input
            v-model="row.value.sms"
            class="checkbox"
            @input="save(row)"
          />
          <span>SMS</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Preloader2 from '@/components/Preloader2';

export default {
  name: 'SettingsNotifications',

  components: { Preloader2 },

  data() {
    return {
      rows: null
    };
  },

  computed: {
    ...mapGetters({
      loading: 'account/loading',
      notifications: 'auth/notifications'
    })
  },
  created() {
    this.rows = [
      {
        title: 'Low balance',
        name: 'low_balance',
        value: {
          email: false,
          sms: false
        }
      },
      {
        title: 'Critical errors',
        name: 'critical_errors',
        value: {
          email: false,
          sms: false
        }
      },
      {
        title: 'Product updates',
        name: 'product_updates',
        value: {
          email: false,
          sms: false
        }
      },
      {
        title: 'Weekly statements',
        name: 'weekly_statements',
        value: {
          email: false,
          sms: false
        }
      }
    ];

    if (this.notifications) {
      const copy = JSON.parse(JSON.stringify(this.notifications));
      this.initFields(copy, this.rows);
    }
  },
  methods: {
    ...mapActions({
      update: 'account/updateNotifications'
    }),

    async save() {
      try {
        await this.update(this.prepareFields(this.rows));
      } catch (error) {
        this.$toast.error(error, {
          position: 'top-left',
          duration: 6000
        });
      }
    },

    initFields(source, fields) {
      for (let key in source) {
        const indx = fields.findIndex(elm => elm.name === key);
        if (indx > -1) {
          fields[indx].value = source[key];
        }
      }
    },

    prepareFields(fields) {
      let notifications = {};
      for (let field of fields) {
        notifications[field.name] = {
          email: field.value.email,
          sms: field.value.sms
        };
      }
      return { notifications };
    }
  },

  
};
</script>

<style lang="scss" scoped>
.settings-notifications {
  display: flex;
  flex-direction: column;

  position: relative;

  .loader-background {
    position: absolute;
    background: blue;
    width: 100%;
    height: 100%;
    z-index: 99999;
    opacity: 0.1;
  }

  .row {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 8px;
  }

  .title {
    @include h2;
    width: 128px;
  }

  .form-item {
    display: flex;
    justify-content: center;
    width: 128px;
    margin-right: 16px;

    &-inner {
      display: flex;
      align-items: center;
      .checkbox {
        margin-right: 16px;
      }
    }
  }
}
</style>
