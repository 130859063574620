import store from '@/store';
import Moment from 'moment';
import DeviceGroupsApi from '@/api/device_groups';
import GatewayGroupsApi from '@/api/gateway_groups';

const BULK_FETCH_TIMEOUT = 250;

const wait = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  })
}

const deviceGroups = () => {
  const watchHandler = async (cachedList) => {
    const from = Moment().subtract(1, 'week').startOf('day');
    const to = Moment();

    for (const key in cachedList) {
      const params = {
        id: cachedList[key],
        from: from.toISOString(),
        to: to.toISOString(),
        detalisation: '5hours',
      };

      await wait(BULK_FETCH_TIMEOUT);

      // TODO: Rename getChart2 ->  ?
      await DeviceGroupsApi.getChart2(params)
        .then(({ data }) => {
          store.dispatch('deviceGroups/setChartData', { id: key, data });
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }

  // store.watch((state) => state.deviceGroups.cachedList, watchHandler, { deep: true });

  store.subscribe((mutation) => {
    if (mutation.type === 'deviceGroups/fillCachedList') {
      watchHandler(store.getters['deviceGroups/cachedList']);
    }
  });

  store.dispatch('deviceGroups/changeParams', { term: null });
  store.dispatch('deviceGroups/getList');
}


const gatewayGroups = () => {
  const watchHandler = async (cachedList) => {
    const from = Moment().subtract(1, 'week').startOf('day');
    const to = Moment();

    for (const key in cachedList) {
      const params = {
        id: cachedList[key],
        from: from.toISOString(),
        to: to.toISOString(),
        detalisation: '5hours',
      };

      await wait(BULK_FETCH_TIMEOUT);

      await GatewayGroupsApi.getChart(params)
        .then(({ data }) => {
          store.dispatch('gatewayGroups/setChartData', { id: key, data });
        }).catch((error) => {
          console.log('error', error);
        });

      console.log(store.getters['gatewayGroups/chartData']);
    }
  }

  store.subscribe((mutation) => {
    if (mutation.type === 'gatewayGroups/fillCachedList') {
      watchHandler(store.getters['gatewayGroups/cachedList']);
    }
  });

  store.dispatch('gatewayGroups/getList');
}

const transactionsData = () => {
  const { range } = store.getters['transactions/periods'][1];
  store.dispatch('transactions/changeParams', { range, limit: { offset: 0, count: 100 } });
  store.dispatch('transactions/getListWithChart');
}

const dataSection = () => {
  store.dispatch('dashboard/data/chart/fetch');
  store.dispatch('dashboard/data/metainfo/fetch');
}

const devicesSection = () => {
  store.dispatch('dashboard/devices/fetch');
}

const prefetchData = () => {
  deviceGroups();
  gatewayGroups();
  transactionsData();
  dataSection();
  devicesSection();
}

prefetchData();