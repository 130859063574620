// import {
//   LogoutView,
//   NotEnoughPermissions,
//   RedirectToSignInView,
// } from "@/views/Auth";

const LogoutView = () => import( /* webpackPrefetch: true */ '@/views/Auth/LogoutView');
const NotEnoughPermissions = () => import( /* webpackPrefetch: true */ '@/views/Auth/NotEnoughPermissions');
const RedirectToSignInView = () => import( /* webpackPrefetch: true */ '@/views/Auth/RedirectToSignInView');

export default [{
  path: '/sign-in',
  name: 'sign-in',
  meta: {
    layout: 'simple',
    guest: true
  },
  component: RedirectToSignInView
}, {
  path: '/redirect-to-sign-in',
  name: 'redirect-to-sign-in',
  meta: {
    layout: 'simple',
    guest: true,
  },
  component: RedirectToSignInView,
}, {
  path: '/not-enough-permissions',
  name: 'not-enough-permissions',
  meta: {
    layout: 'simple',
    guest: true
  },
  component: NotEnoughPermissions
}, {
  name: 'logout',
  path: '/logout',
  meta: {
    layout: 'simple',
    guest: true
  },
  component: LogoutView
},
]
