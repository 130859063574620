<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6667 12.0001C10.6667 6.84542 14.8453 2.66675 20 2.66675C25.1546 2.66675 29.3333 6.84542 29.3333 12.0001C29.3333 17.1547 25.1546 21.3334 20 21.3334H17.3333V24.0001H14.6667V26.6667H12V29.3334H2.66666V22.1145L10.864 13.9171C10.7333 13.2914 10.6667 12.6498 10.6667 12.0001ZM14.6667 18.6667H20C23.6819 18.6667 26.6667 15.682 26.6667 12.0001C26.6667 8.31818 23.6819 5.33341 20 5.33341C16.3181 5.33341 13.3333 8.31818 13.3333 12.0001C13.3333 12.6649 13.4302 13.3153 13.6188 13.9372L13.8505 14.7018L5.33332 23.219V26.6667H9.33332V24.0001H12V21.3334H14.6667V18.6667ZM17.3333 12.0001C17.3333 13.4728 18.5272 14.6667 20 14.6667C21.4727 14.6667 22.6667 13.4728 22.6667 12.0001C22.6667 10.5273 21.4727 9.33341 20 9.33341C18.5272 9.33341 17.3333 10.5273 17.3333 12.0001Z"
      fill="#555FB9"
    />
  </svg>
</template>

<script>
export default {
  name: 'ApiKeysIcon',
  props: {
    fill: {
      default: '#474B6D',
      type: String
    }
  }
};
</script>
