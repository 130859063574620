<template>
  <div
    class="values-block"
    :class="classes"
  >
    <!-- <span>{{ title }}</span> -->

    <FlexChildrenOverflowedText>
      <template #default>
        {{ title }}
      </template>
      <template #content>
        {{ title }}
      </template>
    </FlexChildrenOverflowedText>

    <slot />
    <span>{{ value }}</span>
  </div>
</template>

<script>
import FlexChildrenOverflowedText from '@/views/Devices/FlexChildrenOverflowedText'
export default {
  components: {
    FlexChildrenOverflowedText
  },
  props: {
    title: {
      type: String,
      required: true
    },

    value: {
      type: String,
      required: true
    },

    tint: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isTransparent() {
      return !this.tint;
    },

    classes() {
      const tint = {
        [this.tint]: !!this.tint
      };

      return {
        transparent: this.isTransparent,
        disabled: this.disabled,
        ...tint
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.values-block {
  @include text-body;
  display: flex;
  padding: 14px 16px;
  flex: 1;
  justify-content: space-between;
  border: 1px solid $grey30;
  border-radius: 6px;
  position: relative;

  span:first-child {
    flex: 1;
  }

  & ~ .values-block {
    margin-top: 8px;
  }

  // Tints:
  &.transparent {
    background: none;
    border-color: $grey30;
    color: $grey70;
  }

  &.red {
    background: $brightRed;
    color: $accentRed;
    border-color: $brightRed;
  }

  &.green {
    background: $brightGreen;
    color: $accentGreen;
    border-color: $brightGreen;
  }

  &.disabled {
    color: $grey50;
    border-color: $grey10;
    background: none;
    // pointer-events: none;
  }
}
</style>