export default class GatewayModel {
  constructor(obj) {
    this.id = null;
    this.name = null;
    this.group_uid = null;
    this.created_at = null;
    this.updated_at = null;
    this.blockchain_uid = null;
    this.pub_key = null;
    this.latitude = null;
    this.longitude = null;
    this.status = null;
    this.gateway_group = null;
    this.group_uid = null;

    if (obj) {
      Object.assign(this, obj);
    }
  }

  get dto() {
    return {
      name: String(this.name),
      latitude: Number(this.latitude),
      longitude: Number(this.longitude),
      status: Number(this.status),
      group_uid: String(this.group_uid)
    };
  }
}
