<template>
  <svg
    width="15"
    height="8"
    viewBox="0 0 15 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.904 0H1.32267C0.592178 0 0 0.596954 0 1.33333V6.66667C0 7.40305 0.592178 8 1.32267 8H11.904C12.6345 8 13.2267 7.40305 13.2267 6.66667V5.86433C13.8163 5.64729 14.2222 5.19253 14.2222 4.66667V3.33333C14.2222 2.80747 13.8163 2.35271 13.2267 2.13567V1.33333C13.2267 0.596954 12.6345 0 11.904 0ZM1.77821 6.66667V1.33333H11.0155V6.66667H1.77821ZM4.51411 2.04926H2.73633V6.17424H4.51411V2.04926ZM5.40321 2.04926H7.18099V6.17424H5.40321V2.04926ZM9.84722 2.04926H8.06944V6.17424H9.84722V2.04926Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'BatteryFullIcon',
  props: {
    fill: {
      default: '#6B6E8D',
      type: String
    }
  }
};
</script>
