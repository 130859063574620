import DashboardView from '@/views/Dashboard';

// const DashboardView = () => import("@/views/Dashboard");

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      title: 'Dashboard'
    },
    component: DashboardView
  }
];
