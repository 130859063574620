<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.94043 36.771L9.04261 36.8355C11.4845 38.4631 14.2557 39.5176 17.153 39.9218L17.6639 39.9993L8.94043 26.1045V36.771Z"
      :fill="fill"
    />
    <path
      d="M24.9192 17.0001L32.9785 4.77108L32.8125 4.64195C31.5038 3.5403 30.0617 2.61167 28.521 1.87848L28.3422 1.78809L21.7134 12.0155L24.9192 17.0001Z"
      :fill="fill"
    />
    <path
      d="M23.3607 19.3379L11.8657 1.67236L11.6741 1.76276C10.117 2.47888 8.65748 3.39464 7.33157 4.48748L7.16553 4.62953L20.1166 24.3095L23.3607 19.3379Z"
      :fill="fill"
    />
    <path
      d="M21.7134 26.6854L24.8937 31.4891L37.8064 10.931L37.7425 10.8148C37.0154 9.39654 36.1189 8.0738 35.0731 6.87621L34.8688 6.63086L21.7134 26.5433V26.6854Z"
      :fill="fill"
    />
    <path
      d="M39.1733 14.3918L39.0328 13.8882L33.6685 22.3981V35.1695L34.0644 34.7821C36.0138 32.8797 37.5481 30.5861 38.5688 28.0486C39.5894 25.5111 40.0737 22.7859 39.9907 20.0479C39.9868 18.1327 39.7115 16.228 39.1733 14.3918Z"
      :fill="fill"
    />
    <path
      d="M0.894057 14.0688C0.303041 16.0056 0.001692 18.0209 2.8109e-08 20.0477C-0.000140003 22.6944 0.522919 25.3143 1.53844 27.7537C2.55397 30.193 4.04147 32.4026 5.91355 34.2525L6.38612 34.7044V21.9202L1.04732 13.5781L0.894057 14.0688Z"
      :fill="fill"
    />
    <path
      d="M5.23539 6.50195L5.01826 6.73439C3.99302 7.919 3.1059 9.21888 2.37441 10.6084L2.31055 10.7246L20.1151 39.1341L23.3209 34.0333L5.23539 6.50195Z"
      :fill="fill"
    />
    <path
      d="M23.0919 39.9216C25.8697 39.5614 28.5438 38.625 30.9469 37.171H31.0618V26.582L22.5811 39.999L23.0919 39.9216Z"
      :fill="fill"
    />
    <path
      d="M25.8515 0.845672L25.5449 0.755278C21.9903 -0.251759 18.2304 -0.251759 14.6758 0.755278L14.3564 0.845672L20.1295 9.69135L25.8515 0.845672Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'Logo',

  props: {
    fill: {
      type: String,
      default: '#fff'
    }
  }
};
</script>