import FinancesApi from '@/api/finances';

export default {
  namespaced: true,

  state: {
    list: [],
    days: [],
    meta: {},
    loading: false,
    error: null,

    financesByPlan: null,

    filtersRanges: {
      year: {
        min: null,
        max: null
      }
    },

    params: {
      tarif: '',
      year: (new Date()).getFullYear(),
      month: (new Date()).getMonth() + 1
    }
  },

  actions: {
    async getList(ctx) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const {
          data,
          meta
        } = await FinancesApi.getList(ctx.getters.params);
        ctx.commit('setList', data);
        ctx.commit('setDays', data[0].days);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', error, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async getFilters(ctx) {
      ctx.commit('setError', null);
      try {
        const { data } = await FinancesApi.getFilters(ctx.getters.params);
        ctx.commit('setFiltersRanges', data);
      } catch (error) {
        ctx.dispatch('errors/pushError', error, { root: true });
        ctx.commit('setError', error);
        throw error;
      }
    },


    async getFinancesByPlan(ctx) {
      ctx.commit('setError', null);
      try {
        const { data } = await FinancesApi.getFinancesByPlan();
        ctx.commit('setFinancesByPlan', data[0]);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'finances', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      }
    },

    changeParams(ctx, data) {
      ctx.commit('changeParams', data);
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setDays(state, data) {
      state.days = Object.values(data);
    },

    setList(state, data) {
      state.list = data;
    },

    setFiltersRanges(state, data) {
      state.filtersRanges.year.min = data[data.length - 1].year;
      state.filtersRanges.year.max = data[0].year;
    },

    setFinancesByPlan(state, data) {
      state.financesByPlan = data;
    },

    changeParams(state, data) {
      Object.assign(state.params, data);
    }
  },

  getters: {
    loading: state => state.loading,
    error: state => state.error,
    meta: state => state.meta,
    list: state => (state.list ? state.list : []),
    days: state => (state.days ? state.days : []),
    params: state => state.params,
    filtersRanges: state => state.filtersRanges,
    financesByPlan: state => state.financesByPlan
  }
};
