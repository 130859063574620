<template>
  <portal
    v-if="opened"
    to="popup"
  >
    <transition name="modal">
      <div
        class="modal"
        :class="{ centered }"
      >
        <div
          v-click-outside="clickOutsideHandler"
          class="modal-container"
          :class="{ plain: plainSlots }"
          :style="style"
        >
          <div v-if="loading">
            <div class="loader-background" />
            <preloader2 />
          </div>
          <div
            v-if="arrowUp"
            ref="arrow"
            class="arrow-up"
            :class="{ dark }"
          />
          <div
            v-if="hasHeader"
            class="modal-header"
          >
            <slot name="header" />
          </div>
          <div
            v-if="hasContent"
            class="modal-content"
            :class="{ 'disable-padding': disablePadding }"
          >
            <slot name="content" />
          </div>
          <div
            v-if="hasActions"
            class="modal-actions-set"
            :class="{ 'disable-padding': disableFooterPadding }"
          >
            <slot name="actions" />
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import vClickOutside from 'v-click-outside';
import Preloader2 from '@/components/Preloader2';

export default {
  name: 'Popup',
  components: { Preloader2 },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    opened: {
      type: Boolean,
      required: true,
    },

    arrowUp: {
      type: Boolean,
      default: false,
    },

    width: {
      type: Number,
      default: 600,
    },

    assignTo: {
      type: HTMLElement,
      default: null,
    },

    plainSlots: {
      type: Boolean,
      default: false,
    },

    disablePadding: {
      type: Boolean,
      default: false,
    },

    disableFooterPadding: {
      type: Boolean,
      default: false,
    },

    dark: {
      type: Boolean,
      default: false,
    },

    clickOutside: {
      type: Boolean,
      default: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      position: {
        left: null,
        top: null,
      },
    };
  },

  computed: {
    centered() {
      return !this.assignTo;
    },

    style() {
      return {
        width: this.$props.width + 'px',
        ...this.position,
      };
    },

    hasHeader() {
      return this.$slots.header;
    },

    hasContent() {
      return this.$slots.content;
    },

    hasActions() {
      return this.$slots.actions;
    },
  },

  watch: {
    opened() {
      this.onResize();
    },
  },

  beforeMount() {
    window.addEventListener('keyup', this.onEscapeKeyUp);
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.onEscapeKeyUp);
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    clickOutsideHandler() {
      if (this.clickOutside) {
        this.handleClose();
      }
    },

    handleClose() {
      this.$emit('close');
    },

    onEscapeKeyUp(event) {
      if (event.key === 'Escape') {
        this.$emit('close');
      }
    },

    onResize() {
      if (this.assignTo) {
        const marginTop = 10;
        const targetPos = this.assignTo.getBoundingClientRect();
        const clientWidth = document.documentElement.clientWidth;

        let x = targetPos.x - this.width / 2 + targetPos.width / 2;
        let y = targetPos.bottom + marginTop;

        this.$nextTick(() => {
          if (
            clientWidth - targetPos.right + targetPos.width / 2 <
            this.width / 2
          ) {
            const offset = Math.abs(
              clientWidth - targetPos.right + targetPos.width / 2
            );
            if (this.$refs.arrow) {
              this.$refs.arrow.style.right = offset + 'px';
            }
          } else {
            if (this.$refs.arrow) {
              this.$refs.arrow.style.left = this.width / 2 - 7 + 'px';
            }
          }
        });

        if (x + this.width > clientWidth) {
          x = clientWidth - this.width;
        }

        Object.assign(this.position, {
          left: x + 'px',
          top: y + 'px',
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
$header-height: 72px;
$actions-set-height: 72px;

.loader-background {
  position: absolute;
  background: blue;
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: 0.1;
}

.modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  position: fixed;
  transition: all 0.3s ease;

  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-container {
    transition: all 0.3s ease;
    box-shadow: 0px 1px 34px rgba(71, 75, 109, 0.3),
      0px 1px 2px rgba(71, 75, 109, 0.4);
    border-radius: 4px;
    position: relative;
    // overflow: hidden;

    &.plain {
      .modal-header {
        background: none;
        box-shadow: none;
        padding: 0;
        height: auto;
        overflow: hidden;
      }

      .modal-content {
        background: none;
        padding: 0;
      }
    }

    .arrow-up {
      position: absolute;
      top: 0;
    }

    & .arrow-up:before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      top: -7px;
      left: 0;
      right: 0;
      margin: auto;
      background: $white;
      border-radius: 4px;
      transform: rotate(45deg);
    }

    .arrow-up.dark:before {
      background: $grey10;
    }
  }

  .modal-header {
    display: flex;
    position: relative;
    // height: $header-height;
    background: $white;
    box-shadow: 0px 1px 4px rgba(162, 164, 189, 0.2);
    box-sizing: border-box;
    padding: 16px;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .modal-content {
    // max-height: calc(85vh - #{$header-height} - #{$actions-set-height});
    overflow-y: auto;
    background: $grey10;
    padding: 16px;

    &.disable-padding {
      padding: 0;
    }
  }

  .modal-actions-set {
    // height: $actions-set-height;
    background: $white;
    box-shadow: inset 0px 1px 0px $grey50;
    box-sizing: border-box;
    padding: 16px;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &.disable-padding {
      padding: 0;
    }
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal,
.modal-enter .modal__container,
.modal-leave-active .modal,
.modal-leave-active .modal__container {
  transform: scale(1.1);
}
</style>
