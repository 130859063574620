<template>
  <div class="data">
    <div class="info-container">
      <info-block
        borderless
        class="item"
      >
        <template #title>
          <span>{{ localStat.month ? localStat.month[0].accepted : 0 }}</span>
        </template>
        <template slot="descr">
          last 30 days
        </template>
      </info-block>

      <info-block
        borderless
        class="item"
      >
        <template #title>
          <span>{{ localStat.today ? localStat.today[0].accepted : 0 }}</span>
        </template>
        <template #descr>
          today
        </template>
      </info-block>
    </div>
    <data-chart
      v-if="!graphLoading"
      :chart-data="graph"
      class="chart-block"
    />
  </div>
</template>

<script>
import DataChart from './DataChart';
import Moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { DataChart },

  data() {
    return {
      localStat: {
        today: null,
        month: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      stat: 'deviceGroupStat/dataStat/item',
      graph: 'deviceGroupStat/chart/item',
      graphLoading: 'deviceGroupStat/chart/loading',
    }),
  },

  async activated() {
    try {
      await this.fetchChartData();
      await this.fetchTxStat();
    } catch (error) {
      this.$toast.error(error, {
        position: 'top-left',
        duration: 6000,
      });
    }
  },

  deactivated() {
    this.localStat.today = null;
    this.localStat.month = null;
  },

  methods: {
    ...mapActions({
      getChart: 'deviceGroupStat/chart/getItem',
      changeChartParams: 'deviceGroupStat/chart/changeParams',
      getTxStat: 'deviceGroupStat/dataStat/getItem',
      changeStatParams: 'deviceGroupStat/dataStat/changeParams',
    }),

    fetchChartData() {
      const period = {
        from: Moment().subtract(1, 'week').startOf('day').toISOString(),
        to: Moment().toISOString(),
      };
      this.changeChartParams({
        period,
        detalisation: '5hours',
      });
      const deviceGroupUid = this.$route.params.id;
      this.getChart({ id: deviceGroupUid });
    },

    // TODO: move to vuex
    async fetchTxStat() {
      const todayPeriod = {
        from: Moment().subtract(1, 'day').startOf('day').toISOString(),
        to: Moment().toISOString(),
      };
      const monthPeriod = {
        from: Moment().subtract(1, 'month').startOf('day').toISOString(),
        to: Moment().toISOString(),
      };

      const deviceGroupUid = this.$route.params.id;

      this.changeStatParams({ period: todayPeriod });
      await this.getTxStat({ id: deviceGroupUid });
      this.localStat.today = this.stat;

      this.changeStatParams({ period: monthPeriod });
      await this.getTxStat({ id: deviceGroupUid });
      this.localStat.month = this.stat;
    },
  },
};
</script>

<style scoped lang="scss">
.data {
  display: flex;
  // margin-bottom: 14px;
  .info-container {
    max-width: 52px;
    margin-right: 8px;
    .item {
      margin-bottom: 5px;
    }
  }
  .chart-block {
    width: 100%;
    min-height: 110px;
  }
}
</style>
