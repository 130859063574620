<template>
  <popup
    v-bind="$attrs"
    :width="500"
    disable-padding
    :loading="loading"
    :click-outside="false"
    @close="closePopup"
  >
    <div
      slot="header"
      class="header"
    >
      <span class="title">Deposit funds</span>
      <span class="close-icon"><cross-icon @click.native="closePopup" /></span>
    </div>

    <div
      slot="content"
      class="content"
    >
      <div class="text">
        Indicate the desired amount of replenishment. After entering you will be
        redirected to the payment page.
      </div>

      <labeled-input
        ref="amountInput"
        placeholder="$ Amount"
        :price-input="2"
        @input="handleInput"
        @keyup.enter.native="onEnterClick"
      />

      <div class="errors">
        <span
          v-for="error in errors"
          :key="error.id"
        >{{ error.msg }}</span>
      </div>

      <div class="controls">
        <div class="input-item">
          <checkbox-input v-model="need_send_by_email" />
          <span>Send invoice by email</span>
        </div>

        <div class="input-item">
          <checkbox-input v-model="payNow" />
          <span>Pay invoice now</span>
        </div>

        <button-input
          class="button-input"
          :disabled="!depositButtonEnabled"
          @click.native="onDepositClick"
        >
          Deposit
        </button-input>
      </div>
    </div>
  </popup>
</template>

<script>
// import { injectStripe } from "@/helpers/stripe";
import FinancesApi from '@/api/finances';
import CrossIcon from '@/components/Icons/Cross';
import { mapGetters } from 'vuex';
const MAX_PRICE_AMOUNT = 6000;
const MIN_PRICE_AMOUNT = 1;
export default {
  name: 'DepositFundsPopup',

  components: {
    CrossIcon,
  },

  data() {
    return {
      value: null,
      stripeKey: process.env.VUE_APP_STRIPE_PK,
      loading: false,
      need_send_by_email: true,
      payNow: true,

      errorTypes: [
        {
          id: 0,
          msg: `Max amount can not be greater that ${MAX_PRICE_AMOUNT} $`,
        },
        {
          id: 4,
          msg: `Min amount can not be less that ${MIN_PRICE_AMOUNT} $`,
        },
        {
          id: 1,
          msg: `Amount must be valid number: 0.50 - 10000 $`,
        },
        {
          id: 2,
          msg: `Amount can be only unsigned integer`,
        },
        {
          id: 3,
          msg: `At least one checkbox must be selected.`,
        },
      ],

      errors: [],
    };
  },

  computed: {
    ...mapGetters({
      userData: 'auth/data',
    }),

    depositButtonEnabled() {
      const CHECKBOXES_ERROR_ID = 3;
      this.resetErrorById(CHECKBOXES_ERROR_ID);

      if (!this.value || this.value === '') {
        return false;
      }

      if (this.errors.length > 0) {
        return false;
      }

      if (this.need_send_by_email || this.payNow) {
        return true;
      }

      this.setError(CHECKBOXES_ERROR_ID);
      return false;
    },
  },

  methods: {
    handleInput(value) {
      if (!value || value === '') {
        this.value = value;
        return;
      }

      const INVALID_INPUT_VALUE_ERROR = 1;
      const AMOUNT_ERROR_INDEX_MAX = 0;
      const AMOUNT_ERROR_INDEX_MIN = 4;
      let tmp = value.split(',');
      if (tmp.length == 2 && !isNaN(parseInt(tmp[1]))) {
        tmp = Number(`${tmp[0]}.${tmp[1]}`);
      } else {
        tmp = Number(tmp[0]);
      }
      const numericValue = Number(tmp);
      if (isNaN(numericValue)) {
        this.setError(INVALID_INPUT_VALUE_ERROR);
        return;
      }

      if (numericValue > MAX_PRICE_AMOUNT) {
        this.setError(AMOUNT_ERROR_INDEX_MAX);
        return;
      }

      if (numericValue < MIN_PRICE_AMOUNT) {
        this.setError(AMOUNT_ERROR_INDEX_MIN);
        return;
      }

      this.resetErrors();
      this.value = numericValue;
    },

    resetErrorById(errorId) {
      const foundErrorIndex = this.errors.findIndex((err) => err.id == errorId);
      if (foundErrorIndex > -1) {
        this.errors.splice(foundErrorIndex, 1);
      }
    },

    resetErrors() {
      this.errors = [];
    },

    setError(errorId) {
      const error = this.errors.find((error) => {
        return error.id == errorId;
      });
      if (!error) {
        const err = this.errorTypes.find((errType) => errType.id === errorId);
        if (!err) {
          throw new Error('Wrong error ID');
        }
        this.errors.push(err);
      }
    },

    onEnterClick() {
      this.onDepositClick();
    },

    async onDepositClick() {
      try {
        this.loading = true;
        await this.createInvoice();
      } catch (error) {
        this.$toast.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    async createInvoice() {
      const { value, need_send_by_email, payNow } = this;

      const { data } = await FinancesApi.createInvoice({
        amount: Math.round(value * 100),
        need_send_by_email,
      });

      if (payNow) {
        window.location.href = data[0].invoice.hosted_invoice_url;
      } else {
        this.$toast.success('Invoice successfully sended');
        this.closePopup();
      }
    },

    closePopup() {
      this.$emit('close');
    },
  },
};
</script>


<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  align-self: center;
  color: $notBlack;

  .title {
    @include h2;
  }

  .close-icon {
    cursor: pointer;
  }
}

.content {
  @include text-body;
  padding: 8px;
  color: $notBlack;

  & > * {
    margin-bottom: 8px;
  }

  .text {
    padding-bottom: 16px;
  }

  .errors {
    display: flex;
    flex-direction: column;
    height: 30px;
    color: $accentRed;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
}

// .button-input {
//   margin-left: auto;
// }
</style>
