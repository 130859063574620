import {
  Finances,
  FinancesDayDetails,
  FinancesBalanceChanges
} from '@/views/Finances';
import PaymentSuccessView from '@/views/Finances/payment/PaymentSuccessView';
import PaymentCancelView from '@/views/Finances/payment/PaymentCancelView';

export default [
  {
    path: '/finances',
    component: Finances,
    name: 'finances',
    redirect: { name: 'balance-changes' },
    children: [
      {
        path: '',
        meta: {
          title: 'Finances'
        },
        name: 'balance-changes',
        component: FinancesBalanceChanges
      },
      {
        path: 'details/:date',
        meta: {
          title: 'Finances'
        },
        name: 'finances-day-details',
        component: FinancesDayDetails
      }
    ]
  },
  {
    path: '/payment-success',
    component: PaymentSuccessView,
    meta: {
      layout: 'simple',
      guest: true,
    },
  },
  {
    path: '/payment-cancel',
    component: PaymentCancelView,
    meta: {
      layout: 'simple',
      guest: true,
    },
  }
];
