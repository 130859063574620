<template>
  <div
    v-if="financesDashData.length"
    class="spendings"
  >
    <div class="info-container">
      <info-block
        class="item"
        borderless
      >
        <template #title>
          $ {{ financesDashData[0]["last30Days"] }}
        </template>
        <template #descr>
          last 30 days
        </template>
      </info-block>
      <info-block
        class="item"
        borderless
      >
        <template #title>
          $ {{ financesDashData[0]["today"] }}
        </template>
        <template #descr>
          today
        </template>
      </info-block>
    </div>

    <finances-chart
      v-if="!loading"
      class="chart-block"
      :chart-data="financesDashData[0].chart"
      :multiplier="2"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import FinancesChart from '@/components/Charts/Finances';

export default {
  components: { FinancesChart },
  computed: {
    ...mapGetters({
      loading: 'dashboard/finances/loading',
      financesDashData: 'dashboard/finances/data',
    }),
  },
  async activated() {
    this.changeParams({
      device_group_uid: this.$route.params.id,
    });

    try {
      await this.fetchFinances();
    } catch (error) {
      console.log('finances error', error);
      // this.$toast.error(error, {
      //   position: "top-left",
      //   duration: 6000
      // });
    }
  },

  methods: {
    ...mapActions({
      fetchFinances: 'dashboard/finances/fetch',
      changeParams: 'dashboard/finances/changeParams',
    }),
  },


};
</script>

<style scoped lang="scss">
.spendings {
  display: flex;
  // margin-bottom: 14px;

  .info-container {
    max-width: 100px;
    margin-right: 8px;
    .item {
      margin-bottom: 5px;
    }
  }

  .chart-block {
    width: 100%;
    min-height: 110px;
  }
}
</style>
