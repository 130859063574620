<template>
  <moe-block
    class="day-details-view"
    :loading="loading"
  >
    <template #heading>
      <back-button @click="$router.go(-1)" />
      <h1>{{ title }}</h1>
      <segmented-control
        v-model="selectedSegment"
        :segments="segments"
        class="tabs"
      />
    </template>
    <template #actions>
      <button-input
        :primary="false"
        @click.native="openExport"
      >
        <span>Export</span>
      </button-input>
    </template>
    <template #content>
      <details-heading />
      <details-table />
    </template>
  </moe-block>
</template>

<script>
import Moment from 'moment';
import DetailsTable from './tables/Details';
import DetailsHeading from './blocks/DetailsHeading';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DayDetailsView',
  components: {
    DetailsTable,
    DetailsHeading,
  },
  data() {
    return {
      segments: [
        {
          id: 0,
          name: 'All',
          val: 'all',
        },
        {
          id: 1,
          name: 'Spendings',
          val: 'spendings',
        },
        {
          id: 2,
          name: 'Deposits',
          val: 'deposits',
        },
      ],
      selectedSegment: null,
    };
  },
  computed: {
    ...mapGetters({
      list: 'financesDetails/list',
      loading: 'financesDetails/loading',
      params: 'financesDetails/params',
      title: 'financesDetails/title',
    }),
  },
  watch: {
    selectedSegment: function () {
      // this.changeParams({ filter: this.selectedSegment.val });
      // this.getList();
    },
  },



  methods: {
    ...mapActions({
      getList: 'financesDetails/getList',
      changeParams: 'financesDetails/changeParams',
    }),

    // TODO: move in one place
    openExport(e) {
      e.preventDefault();
      const a = document.createElement('a');
      const file = new Blob([JSON.stringify(this.list)], {
        type: 'text/plain',
      });
      a.href = URL.createObjectURL(file);
      a.download = `finances-details-list-${Moment().format(
        'YYYY_DD_MM_HH_mm_ss'
      )}.json`;
      a.click();
    },
  },


};
</script>

<style lang="scss" scoped>
.day-details-view {
  height: 100%;
  position: relative;

  .tabs {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 360px;
  }
}
</style>
