const lineConfigDefault = {
  chart: {
    backgroundColor: 'rgba(0,0,0,0)',
    spacingLeft: 0,
    spacingRight: 0
  },
  title: {
    text: null
  },
  credits: false,
  legend: {
    enabled: false
  },

  plotOptions: {
    spline: {
      marker: {
        enabled: false
      }
    },
    series: {
      lineWidth: 1,
      color: '#555FB9'
    }
  },
  xAxis: {
    type: 'datetime',
    tickInterval: 1000 * 3600 * 24 * 30 // 1 month
    // tickInterval: 60 * 60 * 1000
  },
  yAxis: {
    title: {
      enabled: false
    }
  }
};

const pieConfigDefault = {
  chart: {
    type: 'pie',
    spacing: [0, 0, 0, 0],
    backgroundColor: 'rgba(0,0,0,0)',
    style: {
      fontFamily: "'Rubik'",
      color: '#6D6E8D'
    },

    // spacingTop: 0,
    // spacingBottom: 0,
    // spacingLeft: 0,
    // spacingRight: 0
  },
  credits: false,
  title: {
    text: 'SLOW',
    align: 'center',
    verticalAlign: 'middle',
    floating: true,
    style: {
      fontSize: '14px'
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false
      }
    }
  }
};

const barConfigDefault = {
  chart: {
    spacing: [10, 0, 0, 0],
    // padding: [10, 10, 10, 10],
    backgroundColor: 'rgba(0,0,0,0)',
    style: {
      fontFamily: "'Rubik'",
      color: '#6D6E8D'
    }
  },
  title: {
    text: null
  },
  credits: false,
  legend: {
    enabled: false
  },
  plotOptions: {
    line: {
      marker: {
        enabled: false
      }
    },
    series: {
      color: '#555FB9'
    }
  },
  xAxis: {
    labels: {
      style: {
        fontFamily: "'Rubik'",
        color: '#6D6E8D'
      }
    },
    crosshair: true
  },

  yAxis: {
    labels: {
      style: {
        fontFamily: "'Rubik'",
        color: '#6D6E8D'
      }
    },
    title: {
      enabled: false
    }
  }
};

const lineConfigInline = {
  chart: {
    spacing: [0, 0, 0, 0],
    backgroundColor: 'rgba(0,0,0,0)'
  },
  title: {
    text: null
  },
  credits: false,
  legend: {
    enabled: false
  },
  plotOptions: {
    spline: {
      marker: {
        enabled: false
      }
    },
    series: {
      color: '#555FB9',
      states: {
        hover: {
          marker: {
            enabled: false,
            fillColor: '#faa'
          }
        }
      }
    }
  },
  xAxis: {
    visible: false
  },
  yAxis: {
    visible: false
  }
};

export {
  lineConfigDefault,
  pieConfigDefault,
  barConfigDefault,
  lineConfigInline
};
