<template>
  <div class="settings-security">
    <div v-if="loading">
      <div class="loader-background" />
      <preloader2 />
    </div>

    <div class="row">
      <div class="title">
        Change your password
      </div>
    </div>

    <div class="row">
      <text-input
        v-model="password"
        placeholder="Current Password"
        class="form-item"
      />
      <text-input
        v-model="new_pass"
        placeholder="New Password"
        class="form-item"
      />
    </div>

    <div class="row">
      <button-input
        :disabled="ifDisabled"
        @click.native="save"
      >
        Update password
      </button-input>
    </div>

    <div class="row title">
      Change your company account
    </div>

    <text-input
      v-model="companyName"
      placeholder="Company name"
      class="row form-item"
      :disabled="!noCompnayAccount"
    />

    <text-input
      v-model="eosName"
      placeholder="Account name"
      class="row form-item"
    />
    <text-input
      v-model="eosPrivate"
      placeholder="Private key"
      class="row form-item"
    />
    <div class="row company-account-agreement">
      <checkbox-input
        v-model="noCompnayAccount"
        class="checkbox"
      />
      <span>My company doesn't have account yet</span>
    </div>

    <div>
      <button-input
        primary
        class="form-item row"
        @click.native.prevent="generateEOS"
      >
        {{
          noCompnayAccount ? `Generate company account` : `Join company account`
        }}
      </button-input>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Preloader2 from '@/components/Preloader2';

export default {
  name: 'SettingsSecurity',

  components: { Preloader2 },

  data() {
    return {
      eosOk: false,

      companyName: null,
      eosName: null,
      eosPublic: null,
      eosPrivate: null,

      password: null,
      new_pass: null,

      noCompnayAccount: false,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'account/loading',
      authLoading: 'auth/loading',
      authData: 'auth/data',
    }),
    ifDisabled() {
      return !this.password || !this.new_pass;
    },
    ifDisabledEOS() {
      return !this.eosName || !this.eosPrivate || !this.eosOk;
    },
  },

  watch: {
    noCompnayAccount() {
      // if (!this.noCompnayAccount) {
      //   this.eosName = null;
      //   this.eosPrivate = null;
      // }
    },

    'authData.eos_private': {
      immediate: true,
      handler: function () {
        if (this.authData.eos_private) {
          this.eosName = this.authData.eos_name;
          this.eosPublic = this.authData.eos_public;
          this.eosPrivate = this.authData.eos_private;
          this.companyName = this.authData.company_name;
        }
      },
    },
  },

  methods: {
    ...mapActions({
      update: 'account/updateSecurity',
    }),

    async save() {
      try {
        await this.update(this.$data);
      } catch (error) {
        this.$toast.error(error, {
          position: 'top-left',
          duration: 6000,
        });
      }
    },

    async generateEOS() {
      // try {
      //   this.localLoading = true;
      //   if (this.noCompnayAccount) {
      //     let result = await UsersApi.generateEOSdata();
      //     for (let key in result.data[0]) {
      //       this.$data[camelCase(key)] = result.data[0][key];
      //     }
      //   } else {
      //     let result = await AuthApi.checkEOSAccount({
      //       eos_name: this.eosName,
      //       eos_private: this.eosPrivate,
      //     });
      //     this.companyName = result.data[0].company_name;
      //   }
      //   await UsersApi.updateUser({
      //     id: this.authData.id,
      //     data: {
      //       eos_name: this.eosName,
      //       // eos_public: this.generateEOSPublicKey(),
      //       eos_private: this.eosPrivate,
      //     },
      //   });
      //   this.$toast.success("Company account updated");
      // } catch (error) {
      //   this.$toast.error(error, {
      //     position: "top-left",
      //     duration: 6000,
      //   });
      // } finally {
      //   this.localLoading = false;
      // }
    },

    generateEOSPublicKey() {
      // const privateWif = ecc.PrivateKey.fromString(this.eosPrivate);
      // return ecc.privateToPublic(privateWif);
    },
  },
};
</script>

<style lang="scss" scoped>
.company-account-agreement {
  display: flex;
  align-items: center;
  .checkbox {
    margin-right: 10px;
  }
}

.settings-security {
  display: flex;
  flex-direction: column;

  position: relative;

  .loader-background {
    position: absolute;
    background: blue;
    width: 100%;
    height: 100%;
    z-index: 99999;
    opacity: 0.1;
  }

  .row {
    display: flex;
    align-items: center;
    height: 45px;
    margin-bottom: 8px;
  }

  .title {
    @include h2;
  }

  .form-item {
    width: 256px;
    margin-right: 16px;
  }
}
</style>
