<template>
  <div class="page-wrapper">
    <!-- <top-panel :title="activeRouteTitle" /> -->

    <top-panel-wrapper v-bind="{ menuItems }" />

    <div class="default-layout">
      <left-panel>
        <navigation-panel />
      </left-panel>
      <div class="main-container">
        <h1 class="page-title">
          {{ $route.meta.title }}
        </h1>
        <div class="main-container-block">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TopPanel from "@/components/TopPanel";
import LeftPanel from '@/components/LeftPanel';
import NavigationPanel from '@/components/NavigationPanel';
import { mapGetters } from 'vuex';
import { TopPanelWrapper } from '@moeco-platform/moeco-components';

export default {
  name: 'DefaultLayout',

  components: {
    // TopPanel,
    TopPanelWrapper,
    LeftPanel,
    NavigationPanel,
  },

  data() {
    return {
      menuItems: [
        // {
        //   id: 0,
        //   title: "Account settings",
        //   route: "user",
        //   color: "regular",
        // },
        {
          id: 1,
          title: 'Log Out',
          route: 'logout',
          color: 'caution',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      userData: 'auth/data',
      settings: 'auth/settings',
    }),

    activeRouteTitle() {
      return this.$route.meta.title || 'default title';
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  width: 100%;
  height: 100vh;
}

.default-layout {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;

  background: $grey30;

  .main-container {
    display: flex;
    flex-direction: column;
    padding: 40px 40px;
    flex: 1;

    .main-container-block {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
    }
  }
}
.page-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #474b6d;
  width: 100%;
  margin-bottom: 25px;
}
</style>
