<template>
  <div class="settings-payment">
    <div
      v-for="group in paymentGroups"
      :key="group.title"
      class="payment-group"
    >
      <h2 class="group-title">
        {{ group.title }}
      </h2>
      <div class="payment-items">
        <payment-roll
          :items="group.items"
          :add-text="group.addText"
          @addCard="addCard($event, group)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentRoll from '@/components/PaymentCard/PaymentRoll';

export default {
  name: 'SettingsPayment',

  components: {
    PaymentRoll
  },

  data() {
    return {
      paymentGroups: [
        {
          title: 'Credit cards',
          items: [
            {
              id: 1,
              cardType: 'visa',
              expMonth: 8,
              expYear: 2021,
              holderName: 'Alexander Khristo',
              number: '4231631185318014'
            }
          ],
          addText: 'Add credit card'
        },
        {
          title: 'Bank accounts',
          items: [
            {
              id: 2,
              cardType: 'Swiss bank checking',
              holderName: 'Alexander Khristo',
              number: '1234703214376575'
            }
          ],
          addText: 'Add bank account'
        }
      ]
    };
  },

  methods: {
    addCard(card, group) {
      this.paymentGroups
        .find(paymentGroup => paymentGroup === group)
        .items.push(card);
    }
  }
};
</script>

<style lang="scss" scoped>
.settings-payment {
  .payment-group {
    .group-title {
      @include h2;
      color: $notBlack;
      margin-bottom: 16px;
    }
  }
}
</style>