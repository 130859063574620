<template>
  <portal to="preloader">
    <div class="preloader-container">
      <div class="loader" />
      <logo
        fill="#555fb9"
        class="logo"
      />
    </div>
  </portal>
</template>

<script>
import Logo from '@/components/Icons/Logo';

export default {
  name: 'Preloader',

  components: {
    Logo
  }
};
</script>

<style lang="scss" scoped>
.preloader-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px;
  }

  .loader:after {
    content: " ";
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid #555fb9;
    margin-left: -4px;
    margin-top: -4px;
    border-color: #555fb9 transparent #555fb9 transparent;
    animation: loader 1.8s ease-in-out infinite;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>