import Request from '@/helpers/request';

export default class DashboardApi {
  static fetch() {
    return Request.client.get('/dashboard');
  }

  static fetchDataChart(params) {
    return Request.client.get('/dashboard/chart', {
      params: DashboardApi.prepareParams(params)
    });
  }

  static fetchDataMetaInfo() {
    return Request.client.get('/dashboard/metainfo');
  }

  static fetchFinances(params) {
    return Request.client.get('/dashboard/finances', { params });
  }

  static fetchDeviceGroups() {
    return Request.client.get('/dashboard/device_groups');
  }

  static fetchDevices() {
    return Request.client.get('/dashboard/devices');
  }

  static fetchGateways() {
    return Request.client.get('/dashboard/gateways');
  }

  static prepareParams(params) {
    return {
      device__device_group_uid: (params.device__device_group_uid) ? params.device__device_group_uid : null,
      period: (params.period) ? params.period : 'week'
    }
  }
}
