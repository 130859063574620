<template>
  <highcharts
    ref="chart"
    v-waypoint="wayPointConfig"
    :options="config"
    class="inline-chart"
  />
</template>

<script>
import Moment from 'moment';
import debounce from 'debounce';

export default {
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      config: {
        chart: {
          animation: false,
          spacing: [0, 0, 0, 0],
          backgroundColor: 'rgba(0,0,0,0)',
          // width: 90,
        },
        title: {
          text: null,
        },
        credits: false,
        legend: {
          enabled: false,
        },
        drilldown: {
          animation: {
            duration: 0,
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
            },
          },
          series: {
            animation: {
              duration: 0,
            },
            color: '#555FB9',
            states: {
              hover: {
                marker: {
                  enabled: false,
                  fillColor: '#faa',
                },
              },
            },
          },
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          visible: false,
        },
        series: [
          {
            type: 'spline',
            enableMouseTracking: false,
            data: [],
          },
        ],
      },
      wayPointConfig: {
        active: true,
        callback: debounce(this.onWaypoint, 200),
        options: {
          intersectionOptions: {
            root: null,
            rootMargin: '0px 0px 0px 0px',
            threshold: [0.4, 0.6], // [0.25, 0.75] if you want a 25% offset!
          }, // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API,
        },
      },
    };
  },

  methods: {
    drawPoints(data, xKey, yKey) {
      const chartdata = [];
      for (let dataItem of data) {
        chartdata.push({
          x: parseInt(Moment(dataItem[xKey]).format('x')),
          y: parseInt(dataItem[yKey]),
        });
      }

      this.$refs.chart.chart.series[0].setData(chartdata, true, false, false);
    },

    onWaypoint: function ({ going /*, direction*/ }) {
      if (going === 'in') {
        if (this.$refs.chart.chart.series[0].xData.length > 0) {
          return;
        }

        this.drawPoints(this.chartData, 'from', 'count');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.inline-chart {
  width: 90px;
  height: 30px;
  padding: 5px;
}
</style>

