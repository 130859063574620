import camelCase from 'camelcase';
// Lazy loading template
export const template = (apiName, listName, api) => {
  const func = async (ctx) => {

    try {
      const { offset, count } = ctx.getters.params.limit;

      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      const { data, meta } = await api[apiName](ctx.getters.params);

      ctx.commit('setMeta' + listName, meta);

      // if (meta.total == ctx.getters[camelCase(listName)].length) {
      //   return;
      // }

      if (offset == 0) {
        ctx.commit('set' + listName, data);
      } else if (offset > 0) {
        ctx.commit('addTo' + listName, data);
      }

      if (offset >= Math.ceil(meta.total / 100) * 100) {
        return;
      }

      ctx.commit('changeParams', { limit: { ...ctx.getters.params.limit, offset: offset + count } });
    } catch (err) {
      console.log('ERROR: ', err);

      // ctx.dispatch("sys/errors/pushError", err, { root: true });
      ctx.commit('setError', err);
      throw err;
    } finally {
      ctx.commit('setLoading', false);
    }

  }

  return func;
};
