<template>
  <div class="settings-view">
    <moe-block
      transparent
      compressed
      scrollable
    >
      <template #content>
        <div class="settings-content">
          <Accordion :items="items">
            <template #content-personal_info>
              <PersonalInfo />
            </template>
            <template #content-security>
              <Security />
            </template>
            <template #content-notifications>
              <Notifications />
            </template>
            <template #content-payment_methods>
              <PaymentGatePortal />
            </template>
          </Accordion>
        </div>
      </template>
    </moe-block>
  </div>
</template>

<script>

import Accordion from '@/components/Accordion';

import PersonalInfoIcon from '@/components/Icons/PersonalInfo';
import WalletIcon from '@/components/Icons/Wallet';
import SecurityIcon from '@/components/Icons/Security';
import ApiKeysIcon from '@/components/Icons/ApiKeys';
import NotificationsIcon from '@/components/Icons/Notifications';

import PersonalInfo from './views/Personal';
import Security from './views/Security';
import Notifications from './views/Notifications';
import PaymentGatePortal from './views/PaymentGatePortal';

export default {
  name: 'SettingsView',

  components: {
    Accordion,
    PersonalInfo,
    Security,
    Notifications,
    PaymentGatePortal,
  },

  data() {
    return {
      items: [
        { name: 'personal_info', title: 'Company information', subtitle: 'Company information', icon: PersonalInfoIcon },
        { name: 'payment_methods', title: 'Payment methods', subtitle: 'Connect where money come from', icon: WalletIcon, expanded: false },
        { name: 'security', title: 'Security', subtitle: 'Passwords and security settings', icon: SecurityIcon },
        { name: 'api_keys', title: 'API keys', subtitle: 'API keys', icon: ApiKeysIcon },
        { name: 'notifications', title: 'Notifications', subtitle: 'SMS and email notifications', icon: NotificationsIcon },
      ]
    };
  },

  created() {
    const activeBlock = this.$route.query.active;
    if (activeBlock) {
      let block = this.items.find((el) => el.name === activeBlock);
      block.expanded = true;
    }
  }
};
</script>

<style lang="scss" scoped>

.settings-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  .settings-content {
    display: flex;
    justify-content: center;
    margin: 80px;
  }

  .content-wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    grid-column-gap: 16px;
    padding: 8px;
    height: 100%;

    .content-block {
      background: $white;
      border-radius: 6px;
      overflow: hidden;
    }
  }

  .settings-navigation {
    display: flex;
    flex-direction: column;

    .nav-item {
      display: flex;
      align-items: center;
      height: 80px;
      transition: $defaultTransition;
      cursor: pointer;

      .nav-icon {
        height: 40px;
        width: 40px;
        background: $grey10;
        color: $darkBlue;
        border-radius: 50%;
        padding: 8px;
        margin: 20px 16px;
        transition: $defaultTransition;
      }

      .nav-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          @include h3;
          color: $grey70;
          transition: $defaultTransition;
        }

        span {
          @include text-body;
          font-weight: normal;
          color: $grey70;
        }
      }

      &.router-link-active {
        background: $grey30;

        .nav-icon {
          background: $white;
          color: $notBlack;
        }

        h3 {
          color: $notBlack;
        }
      }
    }
  }

  .main {
    padding: 24px;

    .content-heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      .content-actions {
        display: flex;
        align-items: center;

        .content-action {
          display: flex;
          align-items: flex-end;

          &:hover {
            text-decoration: underline;
          }

          > * {
            margin: 0 8px;
          }
        }
      }
    }

    h2 {
      @include h1;
      color: $notBlack;
    }
  }
}
</style>
