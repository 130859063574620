<template>
  <div class="progress-bar">
    <div
      class="label"
    >
      Imported: {{ percentage }} %; {{ total }} / {{ current }}
    </div>
    
    <div class="stripe-background">
      <div
        class="stripe-1"
        :style="{
          width: `${percentage}%`,
          borderTopRightRadius: `${(percentage < 100) ? '0px' : '5px' }`,
          borderBottomRightRadius: `${(percentage < 100) ? '0px' : '5px' }`
        }"
      />
    </div>
  </div>
</template>

<script>
export default {  
  props: {
    current: {
      type: Number,
      default: null
    },
    total: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      percentage: 0
    }
  },

  watch: {
    current: function() {
      if (this.current != this.total) {
        this.percentage += Math.floor(100/this.total);
      } else {
        this.percentage = 100;
      }
    }
  }
};
</script>

<style lang="scss" scoped>

$sync-status-height: 32px;

.progress-bar {
  margin-bottom: 2em;
  position: relative;

  &:hover {
    // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    cursor: pointer;
  }

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $sync-status-height;
    position: absolute;
    width: 100%;
    // color: white;
    font-weight: 500;
    z-index: 9999;
  }

  .stripe-background {
    background: #6875c54a;
    height: $sync-status-height;
    border-radius: 5px;
    border-radius: 5px;
  }

  .stripe-1 {
    height: $sync-status-height;
    font-weight: bold;
    text-align: center;
    opacity: .5;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #606dbc;
  }
}
</style>