<template>
  <moe-block
    :heading="`${monthStartRaw.format('MMMM')} spending speed`"
    class="month-spending-block"
  >
    <template #content>
      <FinancesChart
        v-if="data.length"
        :chart-data="data[0].chart"
      />

      <div class="stats">
        <info-block
          class="stats-block"
          borderless
        >
          <template #title>
            $ {{ summary.spent }}
          </template>
          <template #descr>
            <p>Spent</p>
            <p>{{ monthStart }} — Today</p>
          </template>
        </info-block>
        <info-block
          class="stats-block"
          borderless
        >
          <template #title>
            $ {{ summary.deposited }}
          </template>
          <template #descr>
            <p>Deposited</p>
            <p>{{ monthStart }} — Today</p>
          </template>
        </info-block>

        <info-block
          class="stats-block"
          borderless
        >
          <template #title>
            $ 0.00
          </template>
          <template #descr>
            <p>Projected</p>
            <p>spending</p>
          </template>
        </info-block>
      </div>

      <heading-block
        v-if="financesByPlan"
        title="By plans"
        class="by-plans"
      >
        <values-block
          v-for="tarif in tariffsStat"
          :key="tarif.name"
          :title="tarif.name"
          :value="tarif.total_paid"
        />
      </heading-block>
    </template>
  </moe-block>
</template>

<script>
import Moment from 'moment';
import FinancesChart from '@/components/Charts/Finances';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MonthSpendingBlock',
  components: { FinancesChart },
  data() {
    return {
      summary: {},

      monthStart: Moment().startOf('month').format('MMM D'),
      monthStartRaw: Moment(),

      // TODO: move to one VUEX place
      tariffs: [
        { name: 'Per transition', value: 'TARIF_PER_TRX' },
        { name: 'Per sensor', value: 'TARIF_PER_SENSOR' },
        { name: 'Per sensor smart', value: 'TARIF_PER_SENSOR_SMART' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      data: 'dashboard/finances/data',

      // TODO: Loads at `tables/BalanceChanges` component
      // probably need to find generic(root) place of data loading
      days: 'finances/days',
      financesByPlan: 'finances/financesByPlan',
    }),

    tariffsStat() {
      let output = [];
      for (let tariff of this.tariffs) {
        if (tariff.value === this.financesByPlan.tarif) {
          output.push({
            name: tariff.name,
            total_paid: this.financesByPlan.total_paid,
          });
        }
      }
      return output;
    },
  },
  created() {
    this.changeParams({ device_group_uid: null, period: 'week' });
    this.fetch().then(() => {
      this.$set(this, 'summary', this.getSummary());
    });
    // this.getFinancesByPlan();
  },



  methods: {
    ...mapActions({
      fetch: 'dashboard/finances/fetch',
      getFinancesByPlan: 'finances/getFinancesByPlan',
      changeParams: 'dashboard/finances/changeParams',
    }),

    getSummary() {
      let res = { spent: 0, deposited: 0 };
      for (let d of this.days) {
        res.spent += parseFloat(d.spent);
        res.deposited += parseFloat(d.deposited);
      }

      res.spent = parseFloat(res.spent).toFixed(2);
      res.deposited = parseFloat(res.deposited).toFixed(2);

      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.month-spending-block {
  .bar-chart {
    height: 130px;
  }

  .stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;
    margin-top: 24px;

    .stats-block {
      height: 72px;
      display: flex;
      flex-direction: column;

      ::v-deep .block-title {
        @include h2;
      }

      ::v-deep .block-descr {
        @include text-body;
      }
    }
  }

  .by-plans {
    margin-top: 30px;
  }
}
</style>
