<template>
  <popup
    class="add-card-popup"
    v-bind="$attrs"
    :width="480"
    @close="close"
  >
    <div
      slot="header"
      class="header"
    >
      <span class="title">Add new credit card</span>
      <cross-icon @click.native="close" />
    </div>
    <div slot="content">
      <div class="form">
        <text-input
          v-model="card.number"
          placeholder="Enter card number"
        />
        <text-input
          v-model="card.holderName"
          placeholder="Enter cardholder name"
        />
        <div class="credentials">
          <span class="expires">Expires</span>
          <text-input
            v-model.number="card.expMonth"
            placeholder="Month"
            class="small-input"
          />
          <span>/</span>
          <text-input
            v-model.number="card.expYear"
            placeholder="Year"
            class="small-input"
          />
          <span class="security">Security code</span>
          <text-input
            placeholder="CVC"
            class="cvc"
          />
        </div>
      </div>
      <div class="billing">
        <h2 class="billing-header">
          Billing address
        </h2>
        <div class="checkbox-wrapper">
          <checkbox-input v-model="billing.sameAsCompany" />
          <span class="label">Same as company address</span>
        </div>
        <text-input
          v-model="billing.addressOne"
          placeholder="Address line 1"
          class="billing-row"
        />
        <text-input
          v-model="billing.addressTwo"
          placeholder="Address line 2"
          class="billing-row"
        />
        <div class="grid">
          <text-input
            v-model="billing.city"
            placeholder="City"
          />
          <text-input
            v-model="billing.state"
            placeholder="State"
          />
          <text-input
            v-model="billing.country"
            placeholder="Country"
          />
          <text-input
            v-model="billing.zip"
            placeholder="ZIP Code"
          />
        </div>
      </div>
    </div>
    <template slot="actions">
      <button-input
        class="add-button"
        @click.native="addCard"
      >
        Add card
      </button-input>
    </template>
  </popup>
</template>

<script>
import CrossIcon from '@/components/Icons/Cross';
export default {
  name: 'AddCardPopup',

  components: {
    CrossIcon
  },

  data() {
    return {
      card: {
        cardType: null,
        expMonth: null,
        expYear: null,
        holderName: null,
        number: null
      },
      billing: {
        sameAsCompany: false,
        addressOne: null,
        addressTwo: null,
        city: null,
        state: null,
        country: null,
        zip: null
      }
    };
  },

  methods: {
    addCard() {
      this.$emit('add', this.card);
    },

    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  align-self: center;
  color: $notBlack;

  .title {
    @include h2;
  }
}

.form {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  .credentials {
    @include text-body;
    display: flex;
    align-items: center;
    color: $grey70;

    .expires {
      margin-right: 8px;
    }

    .small-input {
      width: 75px;
      margin: 0 8px;
      flex-shrink: 0;
    }

    .security {
      margin-left: 16px;
      margin-right: 10px;
      flex-shrink: 0;
      flex-grow: 1;
      text-align: right;
    }

    .cvc {
      width: 60px;
    }
  }
}

.billing {
  display: flex;
  flex-direction: column;

  .billing-header {
    @include h2;
    color: $notBlack;
    margin: 24px 0 16px;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .label {
      @include text-body;
      margin-left: 16px;
      color: $grey70;
    }
  }

  .billing-row {
    margin-bottom: 8px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    margin-bottom: 8px;
  }
}
</style>