<template>
  <div
    class="info-block"
    :class="classes"
  >
    <div
      class="block-content"
      :class="align"
    >
      <div class="info-wrapper">
        <h1
          v-if="hasTitle"
          class="block-title"
        >
          <slot name="title" />
          <span
            v-if="indicate && tint == 'green'"
            class="indicator"
          >▲</span>
          <span
            v-if="indicate && tint == 'red'"
            class="indicator"
          >▼</span>
        </h1>
        <span
          v-if="hasDescr"
          class="block-descr"
        >
          <slot name="descr" />
        </span>
      </div>
      <div
        v-if="hasContainer"
        class="container"
      >
        <slot name="container" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orientation: {
      type: String,
      default: 'vertical'
    },

    tint: {
      type: String,
      default: 'transparent'
    },

    indicate: {
      type: Boolean,
      default: false
    },

    borderless: {
      type: Boolean,
      default: false
    },

    align: {
      type: String,
      default: 'left' // 'center' | 'right'
    }
  },

  computed: {
    classes() {
      const tint = {
        [this.tint]: !!this.tint
      };

      return {
        vertical: this.orientation == 'vertical',
        horizontal: this.orientation == 'horizontal',
        transparent: this.isTransparent,
        borderless: this.borderless,
        ...tint
      };
    },

    isTransparent() {
      return !this.tint;
    },

    hasTitle() {
      return this.$slots.title;
    },

    hasDescr() {
      return this.$slots.descr;
    },

    hasContainer() {
      return this.$slots.container;
    }
  }
};
</script>

<style lang="scss" scoped>
.info-block {
  display: inline-block;
  border-style: solid;
  border-radius: 6px;
  border-width: 1px;
  padding: 12px 16px;
  transition: $defaultTransition;

  &.borderless {
    border: none;
    padding: 0;

    .block-title {
      @include text-body;
    }

    .block-descr {
      @include text-tiny;
    }

    &.red {
      background: none;
    }

    &.green {
      background: none;
    }
  }

  .block-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  &.horizontal {
    .block-content {
      flex-direction: column;
      align-items: flex-start;
      &.center {
        align-items: center;
      }

      &.left {
        align-items: flex-start;
      }

      .info-wrapper {
        flex-direction: row;
        align-items: center;

        > *:first-child {
          margin-right: 8px;
        }
      }
    }
  }

  &.vertical {
    .block-content {
      // width: 100%;
      height: 100%;

      flex-direction: row;



      &.center {
        justify-content: center;
        // align-items: center;
      }

      .info-wrapper {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .info-wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .block-title {
    @include h3;
    display: inline-flex;
    align-items: center;
  }

  .block-descr {
    @include text-body;
  }

  .indicator {
    margin-left: 6px;
  }

  .container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 16px;
  }

  // Tints:
  &.transparent {
    background: none;
    border-color: $grey30;

    .block-title {
      color: $notBlack;
    }

    .block-descr {
      color: $grey70;
    }
  }

  &.red {
    color: $accentRed;
    background: $brightRed;
    border-color: $brightRed;
  }

  &.green {
    color: $accentGreen;
    background: $brightGreen;
    border-color: $brightGreen;
  }
}
</style>
