import DevicesApi from '@/api/devices';
import { template } from '@/store/helpers/lazyloading'

export default {
  namespaced: true,

  state: {
    list: [],
    importData: null,
    importPopUpShow: false,

    /*
      TODO: Refactoring to

      import: {
        data: null,
        popup: {
          show: false
        }
      }
    */
    meta: {},
    loading: false,
    error: null,
    params: {
      hash: null,
      orderBy: {
        value: 'hash',
        desc: false
      },
      limit: {
        count: 100,
        offset: 0
      },
    }
  },

  actions: {
    async getList(ctx) {

      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const { data, meta } = await DevicesApi.getList(ctx.getters.params);
        ctx.commit('setList', data);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'devices', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async importList(ctx, params) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const { data, meta } = await DevicesApi.importList(params.formData, params.deviceGroupUid);
        ctx.commit('setImportData', data);
        ctx.commit('setMeta', meta);
        ctx.commit('setImportPopUpShow', true);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'devices', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    // Lazy loading
    getLazyList(ctx) {
      console.log('HERE?1')
      return template('getList', 'List', DevicesApi)(ctx);
    },

    resetLazyList(context) {
      context.commit('resetLazyList');
    },

    //// 


    changeParams(context, params) {
      if (params.orderBy) {
        if (context.state.params.orderBy.value === params.orderBy.value) {
          params.orderBy.desc = !context.state.params.orderBy.desc;
        } else {
          params.orderBy.desc = true;
        }
      }
      context.commit('changeParams', params);
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setList(state, data) {
      state.list = data;
    },

    // Lazy loading
    addToList(state, data) {
      state.list = [...state.list, ...data];
    },

    resetLazyList(state) {
      state.list = [];
      state.params.limit.offset = 0;
      state.params.limit.count = 100;
    },

    setMetaList(state, meta) {
      state.listMeta = meta;
    },

    /////

    setImportData(state, data) {
      state.importData = data;
    },

    setImportPopUpShow(state, data) {
      state.importPopUpShow = data;
    },

    changeParams(state, data) {
      Object.assign(state.params, data);
    }
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    list: state => (state.list ? state.list : []),
    params: state => state.params,
    importData: state => state.importData,
    importPopUpShow: state => state.importPopUpShow
  }
};
