<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 15.5002C11.8683 15.5009 11.7379 15.4757 11.616 15.426C11.4942 15.3762 11.3834 15.3029 11.2899 15.2102L7.28994 11.2102C7.1967 11.1169 7.12274 11.0063 7.07228 10.8844C7.02182 10.7626 6.99585 10.632 6.99585 10.5002C6.99585 10.3683 7.02182 10.2378 7.07228 10.1159C7.12274 9.99411 7.1967 9.88342 7.28994 9.79018C7.38318 9.69695 7.49387 9.62299 7.61569 9.57253C7.73751 9.52206 7.86808 9.49609 7.99994 9.49609C8.1318 9.49609 8.26237 9.52206 8.38419 9.57253C8.50601 9.62299 8.6167 9.69695 8.70994 9.79018L11.9999 13.1002L15.2999 9.92019C15.3919 9.8179 15.504 9.73569 15.6293 9.6787C15.7545 9.62171 15.8901 9.59118 16.0276 9.589C16.1652 9.58682 16.3017 9.61304 16.4287 9.66603C16.5556 9.71903 16.6703 9.79764 16.7655 9.89697C16.8607 9.99629 16.9343 10.1142 16.9819 10.2433C17.0294 10.3724 17.0498 10.5099 17.0418 10.6472C17.0338 10.7846 16.9975 10.9188 16.9352 11.0414C16.873 11.1641 16.7861 11.2726 16.6799 11.3602L12.6799 15.2202C12.4971 15.3965 12.2539 15.4966 11.9999 15.5002Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowDownIcon',
  props: {
    fill: {
      default: '#474B6D',
      type: String
    }
  }
};
</script>
