<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m11.0812 7.3265-5.78184 5.4679-1.29936-1.2288 8-7.5656 8 7.5656-1.2994 1.2288-5.7818-5.4679v12.6735h-1.8376z"
        :fill="fill"
      />
    </svg>
    />
  </svg>
</template>

<script>
export default {
  name: 'SpendingIcon',

  props: {
    fill: {
      type: String,
      default: '#DE3C63'
    }
  }
};
</script>