<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 30.6666C7.89983 30.6666 1.33334 24.1001 1.33334 15.9999C1.33334 7.89974 7.89983 1.33325 16 1.33325C24.1002 1.33325 30.6667 7.89974 30.6667 15.9999C30.6667 24.1001 24.1002 30.6666 16 30.6666ZM25.866 22.8328C27.2115 20.8937 28 18.5389 28 15.9999C28 9.3725 22.6274 3.99992 16 3.99992C9.37259 3.99992 4.00001 9.3725 4.00001 15.9999C4.00001 18.5389 4.78852 20.8937 6.13402 22.8328C7.63461 20.8507 11.1608 19.9999 16 19.9999C20.8392 19.9999 24.3654 20.8507 25.866 22.8328ZM23.9529 24.9863C23.5627 23.5831 20.7592 22.6666 16 22.6666C11.2408 22.6666 8.43735 23.5831 8.0471 24.9863C10.1644 26.8615 12.9493 27.9999 16 27.9999C19.0508 27.9999 21.8356 26.8615 23.9529 24.9863ZM16 7.99992C12.7721 7.99992 10.6667 10.3409 10.6667 13.3333C10.6667 17.9031 13.0145 19.9999 16 19.9999C18.9575 19.9999 21.3333 17.9727 21.3333 13.5999C21.3333 10.562 19.2189 7.99992 16 7.99992ZM13.3333 13.3333C13.3333 16.3589 14.4243 17.3333 16 17.3333C17.5703 17.3333 18.6667 16.3978 18.6667 13.5999C18.6667 11.9338 17.6209 10.6666 16 10.6666C14.3117 10.6666 13.3333 11.7544 13.3333 13.3333Z"
      fill="#555FB9"
    />
  </svg>
</template>

<script>
export default {
  name: 'PersonalInfoIcon',
  props: {
    fill: {
      default: '#474B6D',
      type: String
    }
  }
};
</script>
