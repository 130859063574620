
import GatewayGroupsApi from '@/api/gateway_groups';
import GatewayGroupModel from '@/store/models/gateway_group';
import ResponseHandler from '@/helpers/responseHandler';

export default {
  namespaced: true,

  state: {
    item: null,
    meta: {},
    loading: false,
    error: null,
    params: {}
  },

  actions: {
    async getItem(ctx, id) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const { data, meta } = await GatewayGroupsApi.getItem(id);
        const item = ResponseHandler.getOnlyElementOfList(data);
        const model = new GatewayGroupModel(item);
        ctx.commit('setItem', model);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'gateway_group', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async updateItem(ctx, obj) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        const blockhcainUid = (obj && obj.blockchain_uid) ? obj.blockchain_uid : ctx.getters.item.blockchain_uid

        const newItem = new GatewayGroupModel({
          ...ctx.getters.item,
          ...obj
        });

        const { data, meta } = await GatewayGroupsApi.updateItem(
          blockhcainUid,
          newItem.dto
        );

        const item = ResponseHandler.getOnlyElementOfList(data);
        const model = new GatewayGroupModel(item);
        ctx.commit('setItem', model);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'gateway_group', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async createItem(ctx, obj) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        const newItem = new GatewayGroupModel({
          ...ctx.getters.item,
          ...obj
        });

        const { data, meta } = await GatewayGroupsApi.createItem(
          newItem.dto
        );

        const item = ResponseHandler.getOnlyElementOfList(data);
        const model = new GatewayGroupModel(item);
        ctx.commit('setItem', model);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'gateway_group', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },


    async deleteItem(ctx, uid) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        await GatewayGroupsApi.deleteItem(uid);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'gateway_group', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setItem(state, data) {
      state.item = data;
    },
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    item: state => state.item
  }
};
