import Vue from 'vue';
import TransactionsApi from '@/api/transactions';
import Moment from 'moment';

export default {
  namespaced: true,

  state: {
    list: [],
    lazyList: [],
    trxList: [],
    meta: {},
    loading: false,
    error: null,
    params: {
      period: TransactionsApi.allowedPeriods[1],
      searchQuery: null,
      range: null,
      device__device_group_uid: null,
      orderBy: {
        value: 'id',
        desc: true
      },
      limit: {
        count: 100,
        offset: 0
      },

      term: ''
    }
  },

  actions: {

    async getListWithChart(ctx) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const {
          data,
          meta
        } = await TransactionsApi.getList(ctx.getters.params);

        if (meta.count != 0) {
          ctx.commit('addToLazyList', data);
        }
        ctx.commit('setMeta', meta);
        const result = await TransactionsApi.getChart(ctx.getters.params);
        ctx.commit('setChart', result.data);
      } catch (error) {
        console.log('ERROR', error);
        ctx.dispatch('errors/pushError', { id: 'transactions', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async getLazyList(ctx) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const {
          data,
          meta
        } = await TransactionsApi.getList(ctx.getters.params);

        if (meta.count != 0) {
          ctx.commit('addToLazyList', data);
        }

        ctx.commit('setMeta', meta);
      } catch (error) {
        console.log('ERROR', error);
        ctx.dispatch('errors/pushError', { id: 'transactions', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async getList(ctx) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const {
          data,
          meta
        } = await TransactionsApi.getList(ctx.getters.params);
        ctx.commit('setList', data);
        ctx.commit('setMeta', meta);
      } catch (error) {
        console.log('ERROR', error);
        ctx.dispatch('errors/pushError', { id: 'transactions', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async getChart(ctx) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);

      try {
        const {
          data,
          meta
        } = await TransactionsApi.getChart(ctx.getters.params);

        ctx.commit('setChart', data);
        ctx.commit('setMeta', meta);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'transactions', error }, { root: true });
        ctx.commit('setError', error);
        throw error;
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async exportTrxToMail(ctx) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        await TransactionsApi.exportTrxToMail(ctx.getters.params);
        // ctx.commit("setTrxList", data);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'transactions', error }, { root: true });
        ctx.commit('setError', error);
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    async exportTrx(ctx) {
      ctx.commit('setLoading', true);
      ctx.commit('setError', null);
      try {
        let { data } = await TransactionsApi.exportTrx(ctx.getters.params);
        ctx.commit('setTrxList', data);
      } catch (error) {
        ctx.dispatch('errors/pushError', { id: 'transactions', error }, { root: true });
        ctx.commit('setError', error);
      } finally {
        ctx.commit('setLoading', false);
      }
    },

    changeParams(context, params) {
      if (params.orderBy) {
        if (context.state.params.orderBy.value === params.orderBy.value) {
          params.orderBy.desc = !context.state.params.orderBy.desc;
        } else {
          params.orderBy.desc = true;
        }
      }
      if (params.range) {
        if (params.range.start && !params.range.end) {
          params.range.end = Moment(params.range.start.endOf('day'))
        } else if (params.range.end && !params.range.start) {
          params.range.start = Moment(params.range.end.startOf('day'))
        }
        params.range.start = Moment(params.range.start.startOf('day'))
        params.range.end = Moment(params.range.end.endOf('day'))
      }
      context.commit('changeParams', params);
    }
  },

  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },

    setError(state, err) {
      state.error = err;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setList(state, data) {
      console.log('setList')
      state.list = data;
    },

    setLazyList(state, data) {
      Vue.set(state, 'lazyList', data);
    },

    setTrxList(state, data) {
      state.trxList = data;
    },

    addToLazyList(state, data) {
      Vue.set(state, 'lazyList', [...state.lazyList, ...data]);
    },

    setChart(state, data) {
      Vue.set(state, 'chart', data);
    },

    changeParams(state, data) {
      Object.assign(state.params, data)
    }
  },

  getters: {
    meta: state => state.meta,
    error: state => state.error,
    loading: state => state.loading,
    list: state => (state.list ? state.list : []),
    lazyList: state => (state.lazyList ? state.lazyList : []),
    trxList: state => (state.trxList ? state.trxList : []),
    chart: state => (state.chart ? state.chart : []),
    periods: () => TransactionsApi.allowedPeriods,
    params: state => state.params,

    title: state => {
      if (!state.params.range) {
        return '';
      }

      if (!state.params.range.start && !state.params.range.end) {
        return '';
      }

      const start = Moment(state.params.range.start);
      const end = Moment(state.params.range.end);

      if (start.diff(end, 'days') == 0) {
        return start.format('D MMMM, YYYY');
      }

      return `${start.format('D MMMM, YYYY')}-${end.format('D MMMM, YYYY')}`;
    }
  }
};
