import Request from '@/helpers/request';

export default class DeviceGroupsStatApi {
  static getActivityStat(params) {
    return Request.client.get(`/device_group/${params.id}/activity`);
  }

  static getBatteryStat(params) {
    return Request.client.get(`/device_group/${params.id}/battery`);
  }

  static getChart(params) {
    const detalisation = params.detalisation;
    const period = params.period;
    return Request.client.get(`/device_group/${params.id}/graph`, {
      params: { ...period, detalisation }
    });
  }

  static getTxStat(params) {
    const period = params.period;
    return Request.client.get(`/device_group/${params.id}/stat`, {
      params: period
    });
  }
}
