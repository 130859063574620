<template>
  <svg
    width="15"
    height="8"
    viewBox="0 0 15 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.98966 0H12.571C13.3015 0 13.8937 0.596954 13.8937 1.33333V2.13595C14.4829 2.3531 14.8885 2.80769 14.8885 3.33333V4.66667C14.8885 5.19231 14.4829 5.6469 13.8937 5.86405V6.66667C13.8937 7.40305 13.3015 8 12.571 8H1.98966C1.25917 8 0.666992 7.40305 0.666992 6.66667V1.33333C0.666992 0.596954 1.25917 0 1.98966 0ZM2.44466 1.33333V6.66667H11.682V1.33333H2.44466ZM3.40321 2.04926H5.18099V6.17424H3.40321V2.04926ZM7.84722 2.04926H6.06944V6.17424H7.84722V2.04926Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'BatteryMediumIcon',
  props: {
    fill: {
      default: '#6B6E8D',
      type: String
    }
  }
};
</script>
