<template>
  <div class="calendar-shortcuts">
    <span
      v-for="shortcut in shortcuts"
      :key="shortcut.label"
      :class="{ 'active': (current) ? current.label == shortcut.label : false }"
      class="shortcut"
      @click="select(shortcut)"
    >
      {{ shortcut.label }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    shortcuts: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      current: null
    }
  },

  watch: {
    value: {
      handler: function(newVal) {
        this.current = newVal;
      }
    }
  },

  created() {
    this.current = this.value;
  },

  methods: {
    select(shortcut) {
      this.current = shortcut;
      this.$emit('input', this.current);
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar-shortcuts {
  display: flex;
  flex-direction: column;

  .shortcut {
    @include text-body;
    color: $notBlack;
    padding: 8px 16px 8px 0;
    cursor: pointer;

    &.active {
      color: $accentBlue;
    }

    &:hover {
      background: $grey30;
    }
  }
}
</style>
