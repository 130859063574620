<template>
  <div class="device-group">
    <device-group-stats />

    <moe-block
      :loading="genericLoading"
      overflowless
    >
      <template #heading>
        <segmented-control
          v-model="selectedSegment"
          :segments="segments"
          @input="onSegmentedControlValueChanged"
        >
          <template #segment="{ segment }">
            <div class="segment-link">
              <!-- <router-link :to="{ name: segment.route }" class="segment-link"> -->
              {{ segment.name }}
              <!-- </router-link> -->
            </div>
          </template>
        </segmented-control>
      </template>

      <template #actions>
        <button-input
          v-if="ifAddButtonVisible"
          :primary="false"
          :disabled="isDisabled"
          @click.native="addBtnClicked"
        >
          <plus-icon
            slot="icon"
            fill="currentColor"
          />
          <span>
            {{ selectedSegment ? selectedSegment.btnTitle : "" }}
          </span>
          <import
            ref="importDevices"
            type="json"
            @import="onImport"
          />
        </button-input>

        <!-- <button-input :primary="false" @click.native="onClickGetExample">
          Download example
        </button-input> -->
      </template>

      <template #content>
        <router-view />
      </template>
    </moe-block>

    <!-- Opens when we click "import" button at top panel button ( shows when we have at least 1 sensor in group) -->
    <import-devices-popup
      v-bind="importData"
      @close="closeImportPopUp"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DeviceGroupStats from './DeviceGroupStats';
import Import from '@/components/Inputs/Import';
import ImportDevicesPopup from '@/components/Popups/ImportDevicesPopup';
import PlusIcon from '@/components/Icons/Plus';
import { validate } from '../helpers';

const deviceExample = [
  {
    uid: 'aa:bb:cc:dd:ff:03',
    name: 'moeco',
    status: 1,
  },
];

function downloadObjectAsJson(exportObj, exportName) {
  var dataStr =
    'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(exportObj));
  var downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', exportName + '.json');
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export default {
  name: 'DevicesView',

  components: {
    ImportDevicesPopup,
    DeviceGroupStats,
    PlusIcon,
    Import,
  },
  beforeRouteLeave(to, from, next) {
    this.setDeviceGroup({});
    next();
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      importResultOpened: true,

      importData: {
        opened: false,
        devices: [],
      },

      segments: [
        // {
        //   id: 0,
        //   name: "Services",
        //   route: "device-group-services",
        //   btnTitle: "Add service",
        // },
        {
          id: 1,
          name: 'Sensors',
          route: 'device-group-devices',
          btnTitle: 'Add sensors',
        },
        {
          id: 2,
          name: 'Settings',
          route: 'device-group-settings',
        },
      ],
      selectedSegment: null,
    };
  },

  computed: {
    ...mapGetters({
      deviceGroup: 'deviceGroup/item',
      services: 'deviceGroup/services',
      devices: 'devices/list',
      loading: 'deviceGroup/loading',
      deviceLoading: 'device/loading',
      devicesLoading: 'devices/loading',
    }),

    isDisabled() {
      // Disabled if not confirmed

      if (!this.deviceGroup) {
        return false;
      }

      return this.deviceGroup.confirmed;
    },

    ifAddButtonVisible() {
      const settingsSegment = this.selectedSegment
        ? this.selectedSegment.name === 'Settings'
        : false;
      const devicesSegment = this.selectedSegment
        ? this.selectedSegment.name === 'Sensors'
        : false;
      const servicesExists = this.services.length != 0;
      const devicesExists = this.devices.length != 0;

      return (
        (servicesExists && !settingsSegment) ||
        (devicesExists && devicesSegment)
      );
    },

    genericLoading() {
      return this.loading || this.devicesLoading || this.deviceLoading;
    },
  },

  activated() {
    // created() {
    this.fetchDeviceGroup();

    const thisRoute = this.segments.find(
      (segment) => segment.route == this.$route.name
    );

    this.selectedSegment = thisRoute ? thisRoute : this.segments[0];
  },



  methods: {
    ...mapActions({
      getDeviceGroup: 'deviceGroup/getItem',
      getDevicesList: 'devices/getList',
      devicesChangeParams: 'devices/changeParams',
      importList: 'devices/importList',
    }),

    ...mapMutations({
      setDeviceGroup: 'deviceGroup/setItem',
      addService: 'deviceGroup/addService',
    }),

    async fetchDeviceGroup() {
      try {
        await this.getDeviceGroup(this.id);
      } catch (error) {
        this.$toast.error(error, {
          position: 'top-left',
          duration: 6000,
        });
      }
    },
    // TODO: Need hard refactoring
    onImport(devices) {
      validate(devices)
        .then((checkedDevices) => {
          this.importData.devices = checkedDevices.map((d) => {
            const newD = {
              ...d,
              hash: d.uid,
              manufacturer: d.name,
            };

            delete newD.uid;
            delete newD.name;

            return newD;
          });

          this.importData.opened = true;
        })
        .catch((error) => {
          console.log('error', error);
          this.$toast.error('File format is wrong!');
        });
    },

    openFileDialog() {},

    closeImportPopUp() {
      this.importData.devices = [];
      this.importData.opened = false;
      this.$refs.importDevices.$refs.uploadFileInput.value = null;

      try {
        this.devicesChangeParams({
          limit: {
            offset: 0,
            count: 100,
          },
        });

        this.getDevicesList();
      } catch (error) {
        this.$toast.error(error, {
          position: 'top-left',
          duration: 6000,
        });
      }
    },

    onSegmentedControlValueChanged() {
      this.$router.push({
        name: this.selectedSegment.route,
        params: { id: this.id },
      });
    },

    addBtnClicked() {
      if (this.selectedSegment.route === 'device-group-devices') {
        this.$refs.importDevices.$refs.uploadFileInput.click();
      }
      if (this.selectedSegment.route === 'device-group-services') {
        this.addService();
      }
    },

    onClickGetExample() {
      downloadObjectAsJson(deviceExample, 'example-' + new Date());
    },
  },
};
</script>

<style lang="scss" scoped>
.device-group {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 8px;
  align-items: flex-start;

  .segment-link {
    padding: 0 32px 0 32px;
    text-decoration: none;
    color: currentColor;
  }
}
</style>
