<template>
  <div class="settings-personal">
    <div v-if="loading">
      <div class="loader-background" />
      <preloader2 />
    </div>

    <div class="user-info">
      <div
        v-for="row in userData"
        :key="row.title"
        class="row"
      >
        <span class="title">{{ row.title }}</span>
        <div
          v-if="row.edit"
          class="value not-editable"
        >
          <labeled-input
            v-model="row.value"
            class="value-item"
            type="text"
            :placeholder="row.title"
          />
          <button-input
            :primary="false"
            class="value-item"
            @click.native="exit(row)"
          >
            Cancel
          </button-input>
          <button-input @click.native="save(row)">
            Save
          </button-input>
        </div>
        <div
          v-else
          class="value"
        >
          {{ row.value }}
        </div>
        <div
          v-if="!row.edit"
          class="edit-button"
          @click="editClick(row)"
        >
          <Pencil fill="#555FB9" />
          <span>Edit</span>
        </div>
      </div>

      <!-- <div class="row">
        <span class="title">Account picture</span>

        <div class="value">
          <div class="upload-avatar-container" @click="loadAvatar">
            <div class="upload-icon"><Upload/></div>
            <span class="label">upload picture</span>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Pencil from '@/components/Icons/Pencil';
import Preloader2 from '@/components/Preloader2';

export default {
  name: 'SettingsPersonal',
  components: { Pencil, Preloader2 },
  props: {
    mode: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      userData: null
    };
  },

  computed: {
    ...mapGetters({
      loading: 'account/loading',
      personalInfo: 'auth/personalInfo'
    })
  },

  created() {
    this.userData = [
      {
        title: 'Company legal name',
        name: 'company_name',
        value: this.personalInfo.company_name,
        edit: false
      },
      {
        title: 'Company address',
        name: 'company_addr',
        value: this.personalInfo.company_addr,
        edit: false
      },
      {
        title: 'Contact person',
        name: 'contact_person',
        value: this.personalInfo.contact_person,
        edit: false
      },
      {
        title: 'Contact person’s email',
        name: 'contact_email',
        value: this.personalInfo.contact_email,
        edit: false
      }
      // {
      //   title: "Contact person’s phone",
      //   name: 'phone_number',
      //   value: this.personalInfo.phone_number,
      //   edit: false
      // }
      // ,
      // {
      //   title: "Account picture",
      //   value: this.phone,
      //   edit: false
      // }
    ];
  },
  methods: {
    ...mapActions({
      update: 'account/updatePersonalInfo'
    }),
    loadAvatar() {
      alert('load avatar');
    },

    editClick(row) {
      this.hideFileds();
      row.edit = !row.edit;
    },

    hideFileds() {
      for (let item of this.userData) {
        item.edit = false;
      }
    },

    async save(row) {
      row.edit = false;

      try {
        await this.update(this.prepareObj(this.userData));
      } catch (error) {
        this.$toast.error(error, {
          position: 'top-left',
          duration: 6000
        });
      }
    },

    exit(row) {
      row.edit = false;
    },

    prepareObj(fields) {
      let obj = {};
      for (let field of fields) {
        obj[field.name] = field.value;
      }
      return obj;
    }
  },

};
</script>

<style lang="scss" scoped>
.upload-avatar-container {
  cursor: pointer;

  display: flex;
  align-items: center;

  .upload-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $grey10;
    width: 40px;
    height: 40px;
    border-radius: 40px;

    margin-right: 10px;
  }

  .label {
    color: $accentBlue;
  }
}

.settings-personal {
  position: relative;

  .loader-background {
    position: absolute;
    background: blue;
    width: 100%;
    height: 100%;
    z-index: 99999;
    opacity: 0.1;
  }

  .user-info {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      align-items: center;
      height: 40px;
      margin-bottom: 8px;
      color: $notBlack;

      .title {
        @include text-body;
        width: 30%;
      }

      .value {
        @include h2;
        width: 60%;

        &.not-editable {
          width: 70%;
          display: flex;
          justify-content: space-between;
        }

        .value-item {
          margin-right: 10px;
        }
      }

      .edit-button {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;

        cursor: pointer;
        color: $accentBlue;

        &:first-child {
          margin-right: 24px;
        }
      }
    }
  }
}
</style>
