<template>
  <div
    class="add-payment"
    @click="$emit('click')"
  >
    <plus-icon
      class="icon"
      fill="currentColor"
    />
    <span>Add credit card</span>
  </div>
</template>

<script>
import PlusIcon from '@/components/Icons/Plus';
export default {
  name: 'AddCardButton',
  components: { PlusIcon }
};
</script>

<style lang="scss" scoped>
.add-payment {
  height: 174px;
  width: 174px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $grey30;
  border-radius: 6px;
  color: $grey70;
  width: 174px;
  flex-shrink: 0;
  cursor: pointer;
  transition: background-color $defaultTransition;

  .icon {
    color: $notBlack;
  }

  &:hover {
    background: $grey10;
  }
}
</style>
