<template>
  <div class="app">
    <portal-target
      name="popup"
      :multiple="true"
    />
    <portal-target name="preloader" />
    <template v-if="loading">
      <preloader />
    </template>
    <component
      :is="layout"
      v-else
    >
      <keep-alive>
        <router-view />
      </keep-alive>
    </component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SimpleLayout from '@/layouts/Simple.vue';
import DefaultLayout from '@/layouts/Default.vue';
import MasternodeRequest from '@/helpers/request';

const defaultLayout = 'default';

export default {
  name: 'App',

  components: {
    SimpleLayout,
    DefaultLayout,
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      token: 'auth/authToken',
      validPermissions: 'auth/validPermissions',
    }),

    layout() {
      const key = this.$route.meta.layout || defaultLayout;
      return `${key}-layout`;
    },
  },

  watch: {
    validPermissions() {
      const possiblePerms = Object.keys(this.validPermissions);

      let hasPermToMasternode = false;
      for (let perm of possiblePerms) {
        if (perm.includes('masternode')) {
          hasPermToMasternode = true;
          break;
        }
      }

      if (!hasPermToMasternode) {
        this.$router.push({
          name: 'not-enough-permissions',
        });
      }
    },

    token() {
      if (this.token) {
        MasternodeRequest.setToken(this.token);
      }
    },
  },

  created() {
    this.getSettings();
  },

  methods: {
    ...mapActions({
      getSettings: 'constants/getConstants',
    }),
  },
};
</script>


<style lang="scss" scoped>
.app {
  width: 100%;
  height: 100vh;
  background: $white;
}
</style>
