export default class PersonalInfoModel {
  constructor(obj) {
    this.company_name = null;
    this.company_addr = null;
    this.contact_person = null;
    this.contact_email = null;
    this.phone_number = null;

    if (obj) {
      Object.assign(this, obj);
    }
  }

  get dto() {
    return {
      company_name: this.company_name ? String(this.company_name) : '',
      company_addr: this.company_addr ? String(this.company_addr) : '',
      contact_person: this.contact_person ? String(this.contact_person) : '',
      contact_email: this.contact_email ? String(this.contact_email) : '',
      phone_number: this.phone_numbers ? String(this.phone_numbers) : ''
    };
  }
}
