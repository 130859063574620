const characteristicsTypes = [ /*'readable', 'writable',*/ 'notifiable', 'mac' /* , 'location', 'advdata', 'cypher_gps' */];

/**
 * Convert our services/characteristic format stored in database
 * in more convenient for frontend form ( characteristics like array of tags, but not object with true/false).
 * May be moved on frontend codebase.
 */
const formatServices = (services) => {
  let newServices = [];

  if (!services) return [];

  for (let s of services) {
    let newService = { name: s.name, characteristics: [] };
    for (let char of s.characteristics) {
      newService.characteristics.push({
        name: char.name,
        tags: Object.entries(char)
          .filter((c) => c[1] && c[0] !== 'name')
          .map((c) => c[0])
      });
    }
    newServices.push(newService);
  }
  return newServices;
}

/**
 * Convert characteristics from frontend conveniant form to backend
 */
const convertCharacteristics = (char) => {
  let newCharacteristics =
    characteristicsTypes.reduce((acc, cur) => {
      for (let t of char.tags) {
        if (t === cur) {
          acc[cur] = true;
        }
      }

      if (!acc[cur]) {
        acc[cur] = false;
      }
      return acc;
    }, {});

  newCharacteristics.name = char.name;
  return newCharacteristics;
}


/**
 * Build services with backend formed characteristics
 */
const buildServices = (frontServices) => {
  let newServices = [];

  if (frontServices) {
    frontServices.forEach((s) => {
      let newService = Object.assign({}, s);
      delete newService.alt_services;
      newService.characteristics = [];
      s.characteristics.forEach((char) => {
        newService.characteristics.push(convertCharacteristics(char));
      });
      newServices.push(newService);
    });
  }

  return newServices;
}

export {
  formatServices,
  buildServices
};