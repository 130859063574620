<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.6668 17.5002C36.6668 21.6643 33.1347 25.0002 28.8237 25.0002H21.6668V26.9513C23.0875 27.4535 24.2135 28.5795 24.7157 30.0002H33.3335V33.3335H24.7157C24.0293 35.2755 22.1772 36.6668 20.0002 36.6668C17.8231 36.6668 15.9711 35.2755 15.2847 33.3335H6.66683V30.0002H15.2847C15.7868 28.5795 16.9128 27.4535 18.3335 26.9513V25.0002H12.059C7.26066 25.0002 3.3335 21.2912 3.3335 16.6668C3.3335 12.7603 6.15337 9.45662 9.99593 8.5679C11.9647 5.36814 15.7844 3.3335 20.0002 3.3335C24.9679 3.3335 29.3222 6.15658 30.8221 10.2459C34.1955 11.0925 36.6668 14.026 36.6668 17.5002ZM21.6668 31.6668C21.6668 32.5873 20.9206 33.3335 20.0002 33.3335C19.0797 33.3335 18.3335 32.5873 18.3335 31.6668C18.3335 30.7464 19.0797 30.0002 20.0002 30.0002C20.9206 30.0002 21.6668 30.7464 21.6668 31.6668ZM29.3447 13.3609C31.6237 13.6026 33.3335 15.3972 33.3335 17.5002C33.3335 19.7794 31.335 21.6668 28.8237 21.6668H12.059C9.06035 21.6668 6.66683 19.4063 6.66683 16.6668C6.66683 14.1898 8.63488 12.069 11.2882 11.7174L12.167 11.6009L12.5616 10.807C13.7851 8.34573 16.6894 6.66683 20.0002 6.66683C23.9108 6.66683 27.1858 9.00116 27.8965 12.0786L28.1638 13.2357L29.3447 13.3609Z"
      fill="#50589E"
    />
  </svg>
</template>

<script>
export default {
  name: 'GatewaysIcon',
  // props: {
  //   fill: {
  //     default: "#474B6D",
  //     type: String
  //   }
  // }
};
</script>
