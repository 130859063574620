<template>
  <div class="preloader">
    <div class="loader" />
    <logo
      fill="#555fb9"
      class="logo"
    />
  </div>
</template>

<script>

import Logo from '@/components/Icons/Logo';
export default {
  name: 'Preloader2',
  components: { Logo }
};

</script>

<style lang="scss" scoped>

.preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    position: absolute;
    width: 60px;
    height: 60px;
  }

  .loader:after {
    content: " ";
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid #555fb9;
    margin-left: -4px;
    margin-top: -4px;
    border-color: #555fb9 transparent #555fb9 transparent;
    animation: loader 1.8s ease-in-out infinite;
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

</style>
