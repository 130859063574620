// import DataView from "@/views/Data";

const DataView = () => import( /* webpackPrefetch: true */ '@/views/Data');

export default [
  {
    path: '/data',
    name: 'data',
    meta: {
      title: 'Data'
    },
    component: DataView
  }
];
