<template>
  <span class="flex-children-container">
    <span class="text">
      <span>
        <slot />
      </span>
    </span>
    
    <popover
      v-if="triggerTarget"
      :config="{ triggerTarget, trigger: 'mouseenter mouseleave' }"
    >
      <template #content>
        <span class="flex-children-popover-content"><slot name="content" /></span>
      </template>
    </popover>
  </span>
</template>

<script>

import detectElementOverflow from 'detect-element-overflow';
import { Popover } from '@moeco-platform/moeco-components';
import { debounce } from 'debounce';

export default {
  components: {
    Popover
  },

  data() {
    return {
      triggerTarget: null
    }
  },

  mounted() {
    window.addEventListener('resize', debounce(this.checkOverflow, 150));
    this.checkOverflow();
  },

  destroyed() {
    window.removeEventListener('resize', debounce(this.checkOverflow, 150));
  },

  methods: {
    checkOverflow() {
      this.$nextTick(() => {
        const parent = this.$el.querySelector('.text');
        const { collidedRight } = detectElementOverflow(parent.children[0], parent);
        if (collidedRight) {
          this.triggerTarget = parent;
          return;
        }
        this.triggerTarget = null;
      })
    }
  }
}
</script>

<style lang="scss">
.flex-children-popover-content {
  margin: 10px;
}
.flex-children-container {
  display: flex;
  min-width: 0;
  .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

</style>
